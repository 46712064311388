<template>
    <div id="box">
        <div class="box_one flex_column">
            <!-- 导航栏 -->
            <div class="nav">
                <!-- 实时视频 -->
                <div class="nav_box flex">
                    <div style="width: 100%; text-align: center">视频用户列表</div>
                </div>
            </div>
            <!-- 导航栏结束 -->
            <!-- 筛选 -->
            <div class="screen">
                <div class="screen_box flex">
                    <div>筛选 :</div>
                    <el-select v-model="screen" placeholder="全部" @change="changeScreen">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 筛选结束 -->
            <!-- 搜索 -->
            <div class="search flex">
                <div class="search_input flex">
                    <el-input v-model="search" placeholder="群组/用户"></el-input>
                    <img class="mouse" src="@/assets/group/gb1.png" @click="query(1)" alt="" />
                </div>
                <div class="search_but mouse" @click="query()">
                    <img src="@/assets/group/search1.png" alt="" />
                </div>
            </div>
            <!-- 搜索结束 -->
            <!-- 树级控件 -->
            <div class="treeLevel flex_grow" ref="treeLevel"
                :style="{ overflow: $store.state.prohibitRoll ? 'hidden' : 'auto' }">
                <el-tree v-if="openPanel" :data="groupArray" show-checkbox @check="check" :disabled="false"
                    node-key="key" ref="tree" highlight-current :props="defaultProps"
                    :default-checked-keys="checkedArray" :default-expanded-keys="expandedKeys"
                    @node-expand="handleNodeExpand" @node-collapse="collapse" :expand-on-click-node="false">
                    <div :class="['treeLevel_tree', data.isShow ? 'flex_start' : '']" slot-scope="{ node, data }"
                        ref="element">
                        <div class="treeLevel_left flex">
                            <div v-show="!data.children" style="height: 16px;">
                                <img class="userimgw" style="margin-right: 5px" :src="upUserImg(data, 1)" alt="" />
                            </div>
                            <!-- <img class="treeLevel_right_img_one" v-show="data.role == 3 && data.online == 1 && !data.Ingroup" style="margin-right:5px;" src="../assets/group/zzDdy.png" alt=""> -->
                            <div v-html="eachColor(node.label, search1)" :id="data.key" class="treeLevel_left_label"
                                :class="data.GroupType == 2
                        ? 'treeLevel_left_yelow'
                        : data.GroupType == 4 ? 'blue_qs' : ''
                        ">
                                {{ node.label }}
                            </div>
                        </div>
                        <!-- data.isBool &&  -->
                        <div class="treeLevel_right flex" v-show="data.services && data.services.video"
                            style="width: 75px;">
                            <template v-if="data.role == 3">
                                <img class="treeLevel_right_img_tow" v-if="data.video_call == 0"
                                    src="@/assets/video/videoHj.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-else src="@/assets/video/videoHj1.png" alt="" />
                            </template>
                            <template v-else>
                                <img class="treeLevel_right_img_tow" v-if="data.video_call == 0"
                                    src="@/assets/video/videoHj.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-else src="@/assets/video/videoHj1.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-if="data.video_drag == 1"
                                    src="@/assets/video/qlVideo.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-else src="@/assets/video/qlVideo1.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-if="data.video_echo == 0"
                                    src="@/assets/video/videoHc.png" alt="" />
                                <img class="treeLevel_right_img_tow" v-else src="@/assets/video/videoHc1.png" alt="" />
                            </template>
                        </div>
                    </div>
                </el-tree>
            </div>
            <!-- 树级控件 -->
            <!-- <div style="border-top: 1px solid #4f7777; padding: 10px 0px">
                <div class="bottom flex">
                    <div class="bottom_box mouse" v-for="(item) in sPGroupArray" :key="item.id">
                        <img :src="item.url" v-preventReClick @click="groupMethod(item)" alt="" />
                        <div>{{ item.name }}</div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- 通话弹出框 -->
        <!-- <dhPopup ref="dhPopup"></dhPopup> -->
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { _debounce } from "@/method/utils.js";
// import dhPopup from "@/components/dispatchLntercom/dhPopup.vue";
import global from "@/method/globalMethod.js";
import { queryUserService } from "@/administration/dispatchLntercom.js";
import EventBus from "@/eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js"
import userImg from "@/method/userImg.js";
const videoUrlapi = beasconfig.videoUrlapi;
export default {
    components: {
        // dhPopup,
    },
    props: {

    },
    data() {
        return {
            groupArray: [],
            options: [
                {
                    //筛选类型
                    value: "-1",
                    label: "全部",
                },
                {
                    value: "3",
                    label: "在线",
                },
                {
                    value: "1",
                    label: "离线",
                },
            ],
            defaultProps: {
                children: "children",
                label: "label",
            },
            screen: "",
            sPGroupArray: [
                //实时视频群组底部方法
                {
                    id: 1,
                    name: "视频呼叫",
                    url: require("@/assets/group/group7.png"),
                },
                {
                    id: 2,
                    name: "强拉视频",
                    url: require("@/assets/group/group6.png"),
                }
            ],
            search: "",
            search1: "",
            loading: false,
            optionsArray: [],
            list: [],
            pdType: 0, //
            userInfo: {},
            userData: {}, //用户数据
            choiceData: [],   //选中的数据
            checkedArray: [],
            expandedKeys: [],     //展开
            userState: null,
            openPanel: true
        };
    },
    created() {
        let that = this;
        that.userState = JSON.parse(sessionStorage.getItem("userState"));
        that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    },
    beforeDestroy: function () {
        EventBus.$off("nowSpeech");
        EventBus.$off("updateData");
        EventBus.$off("groupMethod");
    },
    mounted() {
        let that = this;
        EventBus.$on("nowSpeech", function (uid, gid, bool) {
            //正在讲话
            that.nowSpeech(uid, gid, bool);
        });
        EventBus.$on("updateData", function (sosInfo) {    //更新单独一条服务数据
            let groupArray = that.groupArray;
            sosInfo = JSON.parse(sosInfo);
            function into(f) {
                if (f < groupArray.length) {
                    let i =
                        groupArray.length > 0
                            ? groupArray[f].children.findIndex(
                                item => item.id == sosInfo.user_id
                            )
                            : -1;
                    if (i >= 0) {
                        let changeChild = {
                            ...groupArray[f].children[i],
                            services: sosInfo.services,
                            GPSswitch: sosInfo.user_gpsswitch == 1 ? true : false,
                            locPeriod: sosInfo.user_gpsfrequency,
                            celllocation_switch: sosInfo.celllocation_switch,
                            audioEnabled: sosInfo.user_audiostatus == 0 ? false : true,
                            video_call: sosInfo.video_call,
                            video_drag: sosInfo.video_drag,
                            video_echo: sosInfo.video_echo
                        };
                        that.$set(that.groupArray[f].children, i, changeChild);
                    }
                    into(f + 1);
                } else {
                    that.refreshTree();
                    that.$store.state.groupUserData = groupArray;
                }
            }
            into(0);
        });
        EventBus.$on("groupMethod", function (row) {
            //强制选中
            that.groupMethod(row);
        });
    },
    methods: {
        eachColor(content, searchVal) {
            let subStr = new RegExp(searchVal, 'gi');
            let nt = '<span style="color:#4affff;">' + searchVal + "</span>";
            return content.replace(subStr, nt) + "&nbsp;";
        },
        upUserImg(data, type) {  //用户对讲图标

            return type == 1 ? userImg.upUserImg(data) : userImg.userServeImg(data);
        },
        refreshTree() {    //刷新树
            this.openPanel = false;
            this.$nextTick(() => {
                this.openPanel = true;
            })
        },
        changeScreen(e) {
            //筛选数据
            let groupUserData = JSON.parse(
                JSON.stringify(this.$store.state.groupUserData)
            );
            groupUserData.forEach(item => {
                if (e == 3) {
                    item.num = item.children.filter(item1 => item1.online == 3).length;
                    item.children = item.children.filter(item1 => item1.online == 3);
                } else if (e == 1) {
                    item.num = 0;
                    item.children = item.children.filter(item1 => item1.online == 1);
                } else {
                    item.num = item.children.filter(item1 => item1.online == 3).length;
                }
            });
            this.groupArray = groupUserData;
            this.search = "";
            this.search1 = "";
            this.screen = e;
            this.expandedKeys = [];
            this.checkedArray = [];
            this.choiceData = [];
            this.$refs.tree.setCheckedKeys([]);
            this.deleteDeploymentCache();
            this.rollBackTree(1);
        },
        check(row, tree) {    //选中树级菜单的值
            this.choiceData = tree.checkedNodes;
            this.checkedArray = tree.checkedNodes
                .filter(item => item.online == 3)
                .map(item => item.key);
            // sessionStorage.setItem("checkedArray", JSON.stringify(this.checkedArray)); //缓存选中的值
        },
        groupMethod(item) {  //左侧切换内容
            // let videoList = JSON.parse(sessionStorage.getItem("videoList"));
            if (this.choiceData.length == 0) {
                this.$MessageWarning(`请选择需要${item.name}的用户！`);
                return
            }
            let choiceData = this.deweight(this.choiceData);
            let isDispatcher = this.choiceData.some(item => item.role == 3);
            let array = item.id == 1 ? choiceData : choiceData.filter(item => item.role != 3);
            let userId = array.map(item => item.id).join(',');
            if (choiceData.length == 1 || item.id == 2) {
                if (item.id == 2 && isDispatcher && array.length <= 0) {
                    this.$MessageWarning('调度员不可强拉！');
                    return
                }
                if (item.id == 1 && this.$store.state.voiceCallInfo.callType != 0) {
                    this.$MessageWarning('正在语音通话！');
                    return
                }
                if (item.id == 1 && this.$store.state.userService.services && this.$store.state.userService.services.video == false) {
                    this.$MessageWarning('请先在管理平台开通视频服务并开启视频呼叫功能！');
                    return
                }
                if (item.id == 1 && this.$store.state.userService.video_call == 0) {
                    this.$MessageWarning('请先在管理平台开启视频呼叫功能！');
                    return
                }
                let userState = this.$store.state.groupOnline[0].onlineArray.find(row => row.id == array[0].id);
                if (userState && userState.online != 3) {     //判断用户是否在线
                    this.$MessageWarning(`用户不在线`);
                    return
                }
                this.videoCall(userId, userState, item.id);
            } else {
                this.$MessageWarning(`只支持对单个用户发起视频呼叫!`, 3000);
            }
            this.eliminateCheckbox();
            this.refreshTree();
            // this.$emit('menuOption', item.id, userId)
        },
        videoCall(uids, user, id) {    //视频呼叫
            let that = this;
            queryUserService({      //首先查看有没有服务
                uId: that.userInfo.id,
                uids: uids
            }).then(res => {
                let userInfo = res.data.data[0];
                if (res.data.code == 200) {
                    let ids = uids.split(',');
                    let vids = [];
                    if (id == 1) {
                        if (!userInfo.services.video) {
                            that.$MessageWarning('此用户未在管理平台开通视频服务！');
                            return
                        }
                        if (userInfo.video_call == 0) {
                            that.$MessageWarning('此用户未在管理平台开启视频呼叫功能！');
                            return
                        }
                        if (that.$store.state.videoCallInfo.callType != 0) {
                            that.$MessageWarning('正在视频通话!');
                            return
                        }
                        axios.get(`${videoUrlapi}-${that.userState.server}/rtc/srs/channels?cid=${that.userInfo.User_CompanyID}&uids=${uids}`,
                            {
                                headers: beasconfig.tokenSl
                            }
                        ).then(res1 => {
                            if (res1.data.code == 200) {
                                let channels = res1.data.data;
                                if (channels.length > 0 && channels.length == ids.length) {
                                    let a = channels.some(item => item.type == 101); //101是正在强拉
                                    let b = channels.some(item => item.type == 201); //201是正在回传
                                    if (b) {
                                        that.$MessageWarning('不能单呼正在回传视频的终端');
                                        return
                                    }
                                    if (a) {
                                        that.$MessageWarning('对方忙');
                                        return
                                    }
                                } else {
                                    ids.forEach(r => {  //筛选出没有回传 强拉 单呼的用户；
                                        let c = channels.some(item => item.uid == r);
                                        if (!c) {
                                            vids.push(r);
                                        }
                                    })
                                    uids = vids.join(',');
                                }
                                let parmas = {
                                    fromUid: that.userInfo.User_ID,
                                    toUids: uids,
                                    type: 200
                                };
                                axios.post(`${videoUrlapi}-${that.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                                    {
                                        headers: beasconfig.tokenSl
                                    }
                                ).then(res2 => {
                                    // console.log(res2)
                                    if (res2.data.code == 200) {
                                        that.eliminateCheckbox();
                                        that.$store.state.videoCallInfo.callName = user.label;
                                        that.$store.state.videoCallInfo.userId = uids;
                                        that.$store.state.videoCallInfo.callType = 1;
                                        that.$store.state.videoCallInfo.switchVideo = 0;
                                        // that.$store.state.videoCallInfo.videoPull = res2.data.data.pull_web;
                                        that.$store.state.videoCallInfo.videoPull = global.replaceParamVal(res2.data.data.pull_web, 'token', that.$store.state.videoToken);
                                        that.$store.state.videoCallInfo.videoPush = global.replaceParamVal(res2.data.data.push_web, 'token', that.$store.state.videoToken);
                                        EventBus.$emit("openPhone");
                                    } else {
                                        that.$MessageWarning(res2.data.message);
                                    }
                                })
                            } else {
                                that.$MessageWarning(res1.data.message);
                            }
                        })
                    } else {
                        let bool = res.data.data.some(item => item.video_drag == 0);
                        let boolTow = res.data.data.some(item => !item.services.video);
                        if (boolTow) {
                            that.$MessageWarning('此用户未在管理平台开通视频服务！');
                            return
                        }
                        if (bool) {
                            that.$MessageWarning('此用户未在管理平台开启强拉视频功能！');
                            return
                        }
                        axios.get(`${videoUrlapi}-${that.userState.server}/rtc/srs/channels?cid=${that.userInfo.User_CompanyID}&uids=${uids}`,
                            {
                                headers: beasconfig.tokenSl
                            }
                        ).then(res1 => {
                            if (res1.data.code == 200) {
                                let channels = res1.data.data;
                                if (channels.length > 0 && channels.length == ids.length) {
                                    let a = channels.every(item => item.type == 101); //101是正在强拉
                                    let b = channels.some(item => item.type == 201); //201是正在回传
                                    if (b) {
                                        that.$MessageWarning('不能强拉正在回传视频的终端');
                                        return
                                    }
                                    if (a) {
                                        that.$MessageWarning('对方忙');
                                        return
                                    }
                                } else {
                                    ids.forEach(r => {  //筛选出没有回传 强拉 单呼的用户；
                                        let c = channels.some(item => item.uid == r && item.type != 200);
                                        if (!c) {
                                            vids.push(r);
                                        }
                                    })
                                    uids = vids.join(',');
                                }
                                EventBus.$emit('EchoUserId', uids);
                                that.$store.state.videoCallInfo.switchVideo = 1;
                            } else {
                                that.$MessageWarning(res1.data.message);
                            }
                        })
                    }
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            });
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr.filter(item => item.online == 3);
        },
        deweightOne(arr) {
            let de_arr = [];
            arr.forEach(i => {
                let isTrue = de_arr.every(j => j != i);
                isTrue ? de_arr.push(i) : "";
            });
            return de_arr;
        },
        replaceParamVal(url, paramName, replaceWith) {       //修改URL参数
            var oUrl = url;
            var re = eval('/(' + paramName + '=)([^&]*)/gi');
            var nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
            return nUrl
        },
        query(type) {
            //查询方法
            //查询方法
            let groupArray = JSON.parse(
                JSON.stringify(this.$store.state.groupUserData)
            );
            // this.groupArray
            if (type == 1) {
                this.search = "";
                this.search1 = "";
                this.rollBackTree(1);
                // return
            }
            this.isQuery = true;
            this.checkedArray = []; //清空选中的值
            this.expandedKeys = [];
            this.choiceData = [];
            this.$refs.tree.setCheckedKeys([]);
            this.deleteDeploymentCache();
            if (this.search !== "") {
                groupArray.forEach(item => {
                    if (
                        item.label.indexOf(this.search) > -1
                    ) {
                        //先检索是否是群组
                        this.expandedKeys.push(item.key);
                        if (item.children.length > 0) {
                            this.handleNodeExpand(item, item, 1);
                        }
                    }
                    item.children.filter((itemOne, index) => {
                        if (
                            itemOne.label.indexOf(this.search) >
                            -1
                        ) {
                            let bool = this.expandedKeys.some(itemTow => itemTow == item.key);
                            if (!bool) {  //判断相同的则不进入
                                this.expandedKeys.push(item.key);
                                this.handleNodeExpand(item, item, 1);
                            }
                            if (itemOne.label == this.search) {
                                item.children[index].queryState = true;
                            }
                        }
                    });
                    if (this.screen == 3) {
                        item.num = item.children.filter(item1 => item1.online == 3).length;
                        item.children = item.children.filter(item1 => item1.online == 3);
                    } else if (this.screen == 1) {
                        item.num = 0;
                        item.children = item.children.filter(item1 => item1.online == 1);
                    }
                });
                this.search1 = this.search;
                this.expandedKeys = this.deweightOne(this.expandedKeys);
                sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
                this.rollBackTree();
            } else {
                // if (this.search.trim() == "") {
                //   this.expandedKeys = [];
                //   this.search = "";
                //   this.search1 = "";
                // }
                this.search1 = "";
                groupArray.forEach(item => {
                    if (this.screen == 3) {
                        item.num = item.children.filter(item1 => item1.online == 3).length;
                        item.children = item.children.filter(item1 => item1.online == 3);
                    } else if (this.screen == 1) {
                        item.num = 0;
                        item.children = item.children.filter(item1 => item1.online == 1);
                    }
                });
            }
            this.groupArray = groupArray;
        },
        rollBackTree(type) {
            if (type == 1) {
                this.$refs.treeLevel.scrollTop = 0;
            } else {
                console.log(this.expandedKeys)
                const node = document.getElementById(this.expandedKeys[0])
                node.scrollIntoView({ block: "start" })
            }
        },
        async handleNodeExpand(row, tree, type) {
            if (type != 1) {
                this.expandedKeys.push(tree.key);
            }
            //展开调用数据
            //展开调用数据
            let i = this.groupArray.findIndex(item => item.id == row.id);
            let array = row.children.map(item => item.id).join(); //集合id
            let bool = row.children.every(item => item.services);
            // let children = [];
            // 获取成员服务状态
            if (!bool) {
                await queryUserService({
                    uId: this.userInfo.id,
                    uids: array
                }).then(res => {
                    res.data.data.forEach(item => {
                        row.children.forEach((item1, index) => {
                            if (item.User_ID == item1.id) {
                                this.$set(this.groupArray[i].children, index, {
                                    ...item1,
                                    ...item
                                });
                            }
                        });
                    });
                });
            }
            sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
            sessionStorage.setItem("groupArray", JSON.stringify(this.groupArray));
            await this.$refs.tree.setCheckedKeys(this.checkedArray);
        },
        collapse(row, tree) {
            //收起
            // console.log(row, tree);
            this.isHighlight = false;
            let index = this.expandedKeys.findIndex(item => item == tree.data.key);
            this.expandedKeys.splice(index, 1);
            sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
        },
        selectKey() {
            //强制选中
            if (this.checkedArray.length > 0) {
                this.$store.state.groupOnline[0].groupListUser.forEach(item => {
                    let i = this.choiceData.findIndex(row => row.key == item.key);
                    if (item.online != 3 && i >= 0) {
                        this.choiceData.splice(i, 1);
                    }
                })
                let checkedArray = this.choiceData.map(item => item.key);
                this.checkedArray = checkedArray;
                setTimeout(() => {
                    this.$refs.tree.setCheckedKeys(this.checkedArray);
                }, 50);
            }
        },
        deleteDeploymentCache() {
            //删除缓存
            sessionStorage.removeItem("openArray");
            // sessionStorage.removeItem("checkedArray");
        },
        nowSpeech(uid, gid, bool) {
            //正在讲话
            let groupArray = this.groupArray;
            let f = groupArray.findIndex(item => item.Gid == gid);
            if (f == -1) {
                return;
            }
            let i = groupArray[f].children.findIndex(item => item.id == uid);
            if (f >= 0 && i >= 0) {
                this.groupArray[f].children[i].isStartSpeech = bool;
            }
        },
        screenData: _debounce(function () {
            let array = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
            if (array.length > 0) {
                array.forEach(item => {
                    if (this.screen == 3) {
                        item.num = item.children.filter(item1 => item1.online == 3).length;
                        item.children = item.children.filter(item1 => item1.online == 3);
                    } else if (this.screen == 1) {
                        item.num = 0;
                        item.children = item.children.filter(item1 => item1.online == 1);
                    }
                    // else if (this.screen == -1)  {
                    //   item.num = item.children.filter(item1 => item1.online == 3).length;
                    // }
                });
                this.groupArray = array.filter(item => item.GroupType != 2);
                // this.groupArray = array;
            }
            this.refreshTree();
            this.selectKey();
        }, 1000),
        eliminateCheckbox() {       //清除选中
            this.checkedArray = [];
            this.choiceData = [];
            this.$nextTick(() => {
                if (this.$refs.tree) {
                    this.$refs.tree.setCheckedKeys([]);
                }
            })
            // this.groupArray.forEach((row) => {
            //     let node = this.$refs.tree.getNode(row.key).parent;
            //     // console.log(node)
            //     // 这里的this.data是指的树的数据;而id是指，:node-key="id"这里的id。从而获取到整个树的node
            //     // 然后便历树，将所有的checked改成false
            //     node.childNodes.map(val => {
            //         val.checked = false;
            //         val.indeterminate = false;
            //         // val.isCurrent = false;
            //         val.isLeaf = false;
            //         if (val.childNodes && val.childNodes.length > 0) {
            //             val.childNodes.map(value => {
            //                 value.checked = false;
            //                 value.indeterminate = false;
            //             })
            //         }
            //     })
            // })
            // this.$refs.tree.setCheckedKeys([]);
        }
    },
    watch: {
        //监听事件
        //监听事件
        "$store.state.groupUserData": {
            handler(newValue) {
                let openArray = JSON.parse(sessionStorage.getItem("openArray"));
                if (openArray && openArray.length > 0) {
                    // if (newValue.length < 0) {
                    //     this.expandedKeys = [];
                    //     sessionStorage.removeItem("openArray");
                    // } else {
                    this.expandedKeys = openArray;
                    // }
                }
                if (newValue.length > 0) {
                    this.screenData();
                }
            },
            // deep: true,
            immediate: true
        }
    },
};
</script>

<style scoped lang="less">
#box {
    width: calc(100% - 1px);
    height: 100%;
    background-color: var(--main-group-color);
    border-right: 1px solid #034050;

    .box_one {
        padding: 0px 20px 0px 12px;
        height: 100%;
        position: relative;
    }

    /deep/.el-tree {
        background-color: transparent;
        color: #fff;

        .el-tree-node__content {
            height: 35px;
            font-size: 14px;
            font-weight: bold;

            &>.el-tree-node__expand-icon {
                font-size: 20px;
                padding: 0px 5px 0px 0px !important;
                margin-left: -5px;
                color: #00FDFF;
            }
        }

        .el-tree-node__content:hover,
        .el-upload-list__item:hover {
            background-color: #043846 !important;
        }

        // .el-tree-node:focus,
        // .is-expanded:focus,
        // .is-focusable:focus {
        //   background-color: #043846 !important;
        // }

        .el-tree-node.is-current>.el-tree-node__content {
            background: #043846;
        }

        .el-tree--highlight-current.el-tree-node.is-current>.el-tree-node__content {
            background: transparent;
        }

        .el-tree .el-tree-node.is-current>.el-tree-node__content {
            background: transparent !important;
        }

        .el-tree-node__expand-icon.expanded {
            color: #00FDFF;
        }

        .el-tree-node__expand-icon {
            color: #00FDFF;
            font-size: 18px;
            transition: none!important;   //去除动画避免有抖动
        }

        .el-tree-node__expand-icon.is-leaf {
            color: transparent !important;
            cursor: default;
        }

        // 禁用的复选框颜色
        .el-checkbox__input.is-disabled .el-checkbox__inner {
            background-color: var(--main-bg-color) !important;
            border-color: #848484 !important;
        }

        .el-tree-node__content>label.el-checkbox {
            margin-right: 10px !important;
        }

        .el-checkbox__inner {
            width: 18px;
            height: 18px;
            background-color: var(--main-bg-color) !important;
            border-color: #00FDFF !important;
            transition: none !important;
        }

        .el-checkbox__inner::after {
            width: 4px;
            height: 10px;
            border: 3px solid var(--main-bg-color);
            border-left: 0;
            border-top: 0;
            left: 5px;
            top: 0px;
            margin-left: 0;
            transition: none !important;
        }

        .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
            top: 7px;
        }

        // .el-checkbox__input.is-checked .el-checkbox__inner,
        // .el-checkbox__input.is-indeterminate .el-checkbox__inner,
        // .el-checkbox__inner:hover {
        //     border-color: #00FDFF;
        // }

        .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #00FDFF !important;
        }

        // .el-checkbox__inner::after {
        //     border-color: var(--main-bg-color);
        //     margin-left: 0;
        // }

        .el-tree-node:focus>.el-tree-node__content {
            background: #043846;
        }

        // .el-tree-node>.el-tree-node__children {
        //   overflow: visible !important;
        // }

        // .el-tree-node.is-expanded.is-focusable .el-tree-node__children {
        //   display: block;
        //   height: auto !important;
        // }

        // .el-tree-node .el-tree-node__children {
        //   height: 0 !important;
        // }

        // .el-tree-node.is-expanded.is-focusable {
        //   overflow: visible;
        // }

        // >.el-tree-node {
        //   overflow-y: clip;
        // }
    }

    .nav {
        width: 100%;
        margin: 0px auto;
        font-size: 18px;
        color: #fff;
        padding-top: 40px;
        padding-bottom: 20px;

        &_box {
            width: 92%;
            margin: auto;
        }
    }

    .screen {
        width: 100%;
        margin: auto;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 15px 0px;

        .screen_box {
            flex: 1;

            /deep/.el-input__inner {
                width: 190px;
                height: 38px;
                line-height: 38px;
                margin-left: 10px;
                // padding: 0px 20px;
                border: 1px solid #25C3C0;
                background-color: transparent;
                color: #ffffff !important;

                .el-input__inner {
                    line-height: 28px;
                }
            }

            /deep/.el-select__input {
                width: 150px !important;
            }
        }

        img {
            width: 44px;
            height: 31px;
        }
    }

    .treeLevel {
        width: calc(100%);
        // height: calc(100vh - 80px - 270px);
        overflow: auto;
        padding: 15px 0px;

        &_box {
            width: 100%;

            span {
                width: 100%;
            }
        }

        &_tree {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 15px;
            //   flex-direction: row;
        }

        &_left {
            flex: 1;
            text-align: left;

            &_label {
                width: 110px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &_yelow {
                color: #ffb400;
            }

            &_ls {
                color: #4eb4b4;
            }
        }

        &_right {
            font-size: 12px;
            color: #8a8a8a;
            margin-right: 8px;

            &_but {
                padding: 0px 2px;
                border-radius: 2px;
                color: #000000;
                font-size: 12px;
            }

            // position: relative;
            &_jr {
                width: 50px;
                // padding: 1px 10px;
                border: 1px solid #8a8a8a;
                border-radius: 50px;
            }

            &_color {
                border: none;
                background-color: #4affff;
                color: #333333;
            }

            &_img {
                width: 16px;
                height: 16px;
                margin-left: 5px;
            }

            &_img_one {
                width: 16px;
                height: 16px;
                margin-left: 5px;
            }

            &_img_tow {
                width: 20px;
                height: 12px;
                margin-left: 5px;
            }
        }

        .treeLevel_popup {
            width: 90px;
            // height: 150px;
            // background-color: #4affff;
            background: url(../../assets/group/leftBg1.png);
            background-size: 100% 100%;
            position: fixed;
            left: 300px;
            top: 0px;
            z-index: 9999;
            color: #ffffff;
            transition: 0.1s;
            font-size: 12px;

            &_box {
                width: 100%;
                margin: auto;

                // margin-bottom: 5px;
                div {
                    text-align: center;
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                }

                div:hover {
                    background-color: #666;
                }

                /deep/.el-switch__core {
                    width: 28px !important;
                    height: 14px !important;
                }

                /deep/.el-switch__core:after {
                    content: "";
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    border-radius: 100%;
                    transition: all 0.3s;
                    width: 10px !important;
                    height: 10px !important;
                    background-color: #fff;
                }
            }

            &_ms {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;

                &_num {
                    width: 25px;
                    height: 13px;
                    line-height: 13px;
                    text-align: center;
                    border: 1px solid #666;
                    color: #ffffff;
                    margin-right: 5px;

                    &_red {
                        color: #4affff !important;
                        border: 1px solid #4affff !important;
                    }
                }
            }
        }
    }

    /deep/.el-input__icon {
        line-height: 28px !important;
    }

    /deep/.el-select .el-input__suffix {
        right: 10px;
    }

    /deep/.el-select .el-input .el-select__caret {
        color: #00FDFF !important;
        font-size: 24px;
    }

    .search {
        width: 100%;
        height: 38px;
        border: 1px solid #4AFFFF;

        &_input {
            flex: 1;
            height: 100%;
            background-color: transparent;
            color: #ffffff !important;

            /deep/.el-input__inner {
                width: calc(100% - 42px);
                // width: 100%;
                height: 30px;
                line-height: 30px;
                background-color: transparent;
                color: #ffffff !important;
                border: none;
                border-radius: 0px;
                padding: 0px;
            }

            img {
                width: 20px;
                height: 19px;
                margin: 0px 13px;
            }
        }

        &_but {
            width: 77px;
            height: 100%;
            // line-height: 30px;
            text-align: center;
            background-color: #00FDFF;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 34px;
                height: 33px;
            }
        }
    }

    .bottom {
        width: 92%;
        // position: fixed;
        // bottom: 0px;
        // left: 4%;
        margin: auto;
        font-size: 14px;
        color: #fff;

        &_box {
            flex: 1;
            // width: 25%;
            text-align: center;

            img {
                width: 41px;
                height: 40px;
            }
        }
    }
}

.blue {
    background-color: #4affff;
}

.blue_qs {
    color: #4eb4b4;
}

.red {
    background-color: red;
}

.grey {
    background-color: #666666;
}

// .margin_bottom {
//     margin-bottom: 5px;
// }

.none {
    visibility: hidden;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_start {
    display: flex;
    align-items: flex-start !important;
}

::-webkit-scrollbar {
    width: 0px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 0px;
    background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}

/deep/ .el-input__inner::placeholder {
    color: #ffffff !important;
}
</style>