<template>
  <el-container class="flex_column">
    <!-- 导航栏 -->
    <el-header class="flex_align" :style="$store.state.displayArea.top">
      <div class="flex_align" :style="$store.state.displayArea.top">
        <div class="tab tab_left prohibitCopy">
          <div class="tab_box" v-for="(item) in tabArrayLeft" :key="item.name" @click="changeTab(item)">
            <div :class="$route.meta.navId == item.id ? 'tab_color' : ''">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="logo">
          <img class="img" src="../assets/navigationbar/logo.png" alt="" />
        </div>
        <div class="tab flex_between prohibitCopy">
          <div class="flex_align tab_right">
            <div class="tab_box" v-for="(item) in tabArrayRight" :key="item.name" @click="changeTab(item)">
              <div :class="$route.meta.navId == item.id ? 'tab_color' : ''">
                {{ item.name }}
              </div>
            </div>
          </div>
          <img style="margin-right: 40px;" @click="bindOpenGb('广播')" src="../assets/gb.png" alt="">
        </div>
      </div>
    </el-header>
    <!-- 导航栏结束 -->
    <el-container class="flex_grow" style="position:relative;">
      <div v-if="$route.meta.navId == 1"
        :class="['containerleft', $store.state.isLeftHome ? 'left_show' : 'left_hide']">
        <!-- 调度对讲群组 -->
        <group v-show="$store.state.isLeftHome" ref="group"></group>
        <div v-if="$store.state.isLeftHome" class="containerleft_zk" @click="$store.state.isLeftHome = false">
          <img class="open_img" src="../assets/open.png" alt="">
        </div>
        <img v-if="!$store.state.isLeftHome" class="open_img rotate_left" @click="$store.state.isLeftHome = true"
          src="../assets/open.png" alt="">
      </div>
      <div v-else class="containerleft">
        <!-- 电子围栏 -->
        <enclosure v-if="$route.meta.navId == 2"></enclosure>
        <!-- 实时视频 -->
        <realtime-video v-if="$route.meta.navId == 3"></realtime-video>
        <!-- 历史记录 -->
        <sidebar v-if="$route.meta.navId == 4" @sidebarTab="sidebarTab"></sidebar>
      </div>

      <!-- 右侧地图 -->
      <div class="main flex_column prohibitCopy">
        <template v-if="$route.meta.navId == 4 || $route.meta.isRecord">
          <div class="main_broder"></div>
          <div :class="['position_relative flex_grow main_height', $route.meta.navId != 1 ? 'main_history' : '']" >
            <div class="main_history_box">
              <router-view v-if="$route.name != 'Home'" />
            </div>
          </div>
        </template>
        <div class="position_relative flex_grow" v-else>
          <router-view v-if="$route.name != 'Home'" />
        </div>
        <callkey></callkey>
        <videoLntercom ref="videoLntercom"></videoLntercom>
        <dhPopup ref="dhPopup"></dhPopup>
        <!-- 数据分析 -->
        <dataAnalysis :userState="userState"></dataAnalysis>
      </div>
      <statistics v-if="$route.meta.navId != 1 && $store.state.isDataAnalysis"></statistics>
      <div v-if="$route.meta.navId == 1"
        :class="['containerright', $store.state.isRightHome ? 'right_show' : 'right_hide']">
        <statistics v-if="$store.state.isRightHome"></statistics>
        <div v-if="$store.state.isRightHome" class="containerright_zk" @click="$store.state.isRightHome = false">
          <img class="open_img" src="../assets/open.png" alt="">
        </div>
        <img v-if="!$store.state.isRightHome" class="open_img rotate_right" @click="$store.state.isRightHome = true"
          src="../assets/open.png" alt="">
      </div>
      <usermenu></usermenu>
    </el-container>
    <!-- 消息提示 -->
    <dialogbox ref="dialogbox" :dialogTitle="dialogTitle" :openGb="openGb" :width="dialogWidth"></dialogbox>
  </el-container>
</template>

<script src=""></script>
<script>
import axios from "axios";
import qs from "qs";
import group from "@/components/dispatchLntercom/group.vue";
import mapcustomfence from "@/components/mapcustomfence.vue";
import dataAnalysis from "@/components/dataAnalysis.vue";
import statistics from "@/components/statistics.vue";
import usermenu from "@/components/usermenu.vue";
import sidebar from "@/components/history/sidebar.vue";
import enclosure from "@/components/enclosure/enclosure.vue";
import realtimeVideo from "@/components/realtimeVideo/index.vue";
import videoLntercom from "@/components/dispatchLntercom/videoLntercom.vue";
import dialogbox from "@/components/dispatchLntercom/dialogbox.vue";
import dhPopup from "@/components/dispatchLntercom/dhPopup.vue";
import callkey from "@/components/callkey.vue";
import { _debounce } from "@/method/utils.js";
import {
  getUserInfo,
  updateUserInfo,
  queryUserService
} from "@/administration/dispatchLntercom.js";
import EventBus from "../eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js"
const videoUrlapi = beasconfig.videoUrlapi;
export default {
  components: {
    group: group,
    mapcustomfence,
    dataAnalysis,
    realtimeVideo,
    enclosure,
    sidebar,
    videoLntercom,
    dhPopup,
    callkey,
    statistics,
    dialogbox,
    usermenu
  },
  data() {
    return {
      tabArrayLeft: [
        //导航栏数据
        {
          id:1,
          lapse: "el-icon-s-marketing",
          name: "调度对讲",
          good: "/intercom/baiduMap",
          routerName:'baiduMap'
        },
        {
          id:2,
          lapse: "el-icon-s-marketing",
          name: "电子围栏",
          good: "/enclosure/enclosureMap",
          routerName:'enclosureMap'
        }
      ],
      tabArrayRight:[
        {
          id:3,
          lapse: "el-icon-s-marketing",
          name: "实时视频",
          good: "/promotion/videoInfo"
        },
        {
          id:4,
          lapse: "el-icon-s-marketing",
          name: "历史记录",
          good: "/history/recording",
          routerName:'recording'
        }
      ],
      huxing: [{ namekey: "首页", keymot: "/dashboard" }], //父传子数据
      isDataAnalysis: false, //数据分析
      userState: {
        isBindRoad: 0, // 是否绑路 0 否 1是
        sosVoice: 0, //sos声音开关
        speechRecognition: 0, //语音识别
        fullDuplex: 0, //全双工
        fullDuplexAudioTime: 0,
        isTalkButton: 0, //对讲按键显示
        enclosureVoice: 0 //围栏声音
      },
      recordNum:0,   //记录次数
      openGb: false,
      dialogWidth: "956px", //对话框的宽度  50%
      dialogTitle: "",
    };
  },
  created() {
    // 创建后
    let that = this;
    that.$store.state.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(that.$route.name == "Home"){   //第一次进入默认跳到百度地图
      that.$router.push(that.tabArrayLeft[0].good)
    }
    that.getUserInfo();
    that.init();
    var isKeyDown = "";
    //按空格的事件
    var time = 0;
    var set = null;
    document.onkeydown = function(e) {
      //键盘按下去的事件
      let key = window.event.keyCode;
      if (e.target.nodeName == "TEXTAREA" || e.target.nodeName == "INPUT") {
        //是否在输入框

        return;
      }
      if (key == 32) {
        that.$store.state.prohibitRoll = true; //禁止滚动条滚动
        if (that.$store.state.isBroadcast) {
          //是否有人发送广播
          return;
        }
        if (that.$store.state.pttIntercom) {
          //解决ptt和空格讲话冲突
          return;
        }
        if (!that.$store.state.isFocusing && !that.$store.state.pttIntercom) {
          if (time == 3) {
            if(that.$store.state.videoCallInfo.callType != 0 || that.$store.state.voiceCallInfo.callType != 0){
              if(that.recordNum == 0){
                if(that.$store.state.videoCallInfo.callType != 0){
                  that.$MessageWarning('正在视频呼叫！');
                }
                if(that.$store.state.voiceCallInfo.callType != 0){
                  that.$MessageWarning('正在语音通话！');
                }
                that.recordNum = 1;
              }
              return
            }
            if (that.$store.state.audio_enable == 1) {
              EventBus.$emit("speechType", 1);
              that.$store.state.pttIntercom = true;
              that.$store.state.isTextBb = false;
            } else {
              that.$store.state.pttIntercom = true;
              that.$MessageWarning('您已被关闭发言！');
            }
          }
          time++;
          // 键盘已暗下去iskeydown就等于onkeydown
          isKeyDown = "onkeydown";
        }
      }
    };
    document.onkeyup = function(e) {
      // 如果离开了空格 那就将计时器清空 iskeydown状态还原 time还原
      // if (e.target.nodeName == 'TEXTAREA' || e.target.nodeName == 'INPUT') {      //是否在输入框

      //   return;
      // };
      let key = window.event.keyCode;
      if (key == 32) {
        that.recordNum = 0;
        that.$store.state.prohibitRoll = false; //禁止滚动条滚动
        if (that.$store.state.isBroadcast) {
          //是否有人发送广播

          return;
        }
        if(that.$store.state.audio_enable != 1){
          that.$store.state.pttIntercom = false;
          
          time = 0;
           return
        }
        if (that.$store.state.pttIntercom) {
          isKeyDown = "";
          if (time >= 3) {
            EventBus.$emit("speechType", 2);
            that.$store.state.pttIntercom = false;
          }
          time = 0;
        }
      }
    };
  },
  beforeDestroy(){
      // EventBus.$off();
      this.$store.state.videoToken = null;
      this.$store.state.recordAddRegionNum = 0;
      window.removeEventListener("popstate",() => {
            history.pushState(null, null, document.URL)
        }, false);
      window.onbeforeunload = null;
      EventBus.$off("openPhone");
      EventBus.$off("openPhoneCall");
    },
  mounted() {
    let that = this;
    // window.addEventListener('online',  function(){
    //  // 网络由异常到正常时触发
    // });
    window.addEventListener('offline', function(){
     // 网络由正常常到异常时触发
      that.messageTips();
    });
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', () => {
        history.pushState(null, null, document.URL)
    })
    window.onbeforeunload = function(e) {
      e = e || window.event;
      if (e) {
        sessionStorage.removeItem("openArray");
        sessionStorage.removeItem("checkedArray");
        e.returnValue = "确认刷新并退出临时群组吗？";
      }
      EventBus.$emit("changePassword",1);
      if(that.$store.state.recentlyCallRecord.length > 0){
        sessionStorage.setItem("recentlyCallRecord",JSON.stringify(that.$store.state.recentlyCallRecord));
      }
      return "确认刷新并退出临时群组吗？";
    };
    document.addEventListener("visibilitychange", function() {
      //浏览器离开页面
      if (document.visibilityState == "hidden") {
        //切离该页面时执行
        if (that.$store.state.userSpeech.isShow) {
          that.$store.state.userSpeech.isShow = false;
          that.$store.state.userSpeech.isBool = false;
        }
      }
      // else if (document.visibilityState == "visible") {
      //   //切换到该页面时执行
      //   console.log(123);
      // }
    });
  },
  destroyed() {
    // EventBus.$off("screenData");
  },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("openPhone", function() {
        that.$refs.videoLntercom.openPhone(1);
      });

      EventBus.$on("openPhoneCall", function(type) {  //打开语音通话
        that.$refs.dhPopup.openPhone(type);
      });
    },
    bindOpenGb(title) {
      //显示广播
      let groupArray = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      let array = groupArray.filter(item => item.isRadioBroadcast == true);
      if (array && array.length < 1) {
        this.$MessageWarning(`请先开启接收广播的群组`);
      } else {
        this.$refs.dialogbox.openPg(2);
        this.openGb = true;
        this.dialogWidth = "956px";
        this.dialogTitle = title;
      }
    },
    handleCommand(e) {
      //下拉菜单
      if (e < 5) {
        let data = {
          uId: this.$store.state.userInfo.id,
          isBindRoad: this.userState.isBindRoad,
          sosVoice: this.userState.sosVoice,
          speechRecognition: this.userState.speechRecognition,
          fullDuplex: this.userState.fullDuplex,
          isTalkButton: this.userState.isTalkButton,
          enclosureVoice: this.userState.enclosureVoice
        };
        updateUserInfo(data).then(res => {
          console.log(res);
        });
      }
      if (e == 5) {
        // 修改密码
        this.$refs.popupPassword.dialogModifyPasswode = true;
      }
      if (e == 6) {
        this.signOutLogin();
      }
    },
    //退出登录
    signOutLogin() {
      let that = this;
      that
        .$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
            EventBus.$emit("changePassword");
        })
        .catch(() => {});
    },
    // 获取用户信息
    getUserInfo() {
      let that = this;
      getUserInfo({
        uId: that.$store.state.userInfo.id
      }).then(res => {
        if (res.data.code == 200) {
          console.log(res)
          that.userState = res.data.data;
          sessionStorage.setItem("userState", JSON.stringify(res.data.data));
          that.$store.state.userState = res.data.data;
          that.$store.state.userState.isLoading = true;
          that.getVideoToken();
        }else{
          that.$MessageWarning(res.data.msg);
        }
      });
      queryUserService({      //用户服务
                uId: that.$store.state.userInfo.id,
                uids: JSON.stringify(that.$store.state.userInfo.User_ID)
            }).then(res => {
                let userInfo = res.data.data[0];
                if (res.data.code == 200) {
                   that.$store.state.userService = userInfo;
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            });
    },
    changeSwitch(bool, type) {
      //switch切换状态
      if (type == 1) {
        this.userState.isTalkButton = bool ? 1 : 0;
      } else if (type == 2) {
        this.userState.sosVoice = bool ? 1 : 0;
      } else if (type == 3) {
        this.userState.enclosureVoice = bool ? 1 : 0;
      } else {
        this.userState.isBindRoad = bool ? 1 : 0;
      }
    },
    changeTab(row) {
      //顶部导航栏切换
      if (this.$route.meta.navId == row.id) {
        return;
      }
      this.$router.push(row.good);
      this.$store.state.groupData = [];
      this.$store.state.efId = 0;
      sessionStorage.removeItem("openArray");
      sessionStorage.removeItem("checkedArray");
    },
    sidebarTab(index) {
      //历史记录左侧切换
      this.$refs.history.historyTab(index);
    },
    getVideoToken(){   //获取视频token 
          let that = this;
          let time = that.$moment(new Date()).valueOf();
          let ukey = that.$md5(`${that.$store.state.userInfo.User_ID}${that.$md5('1')}${JSON.stringify(time)}`)
          let parmas = {
              uid:that.$store.state.userInfo.User_ID,
              time:time,
              ukey:ukey
            };
            axios.post(`${videoUrlapi}-${that.userState.server}/rtc/auth/token`, qs.stringify(parmas),
            {
              headers: beasconfig.tokenSl
            }
            )
                .then(res => {
                    if (res.data.code == 200) {
                        // console.log(res.data.data.token)
                        that.$store.state.videoToken = res.data.data.token;
                    }
              })
    },
    messageTips:_debounce(function(){
            this.$MessageWarning("无网络链接");
            EventBus.$emit("changePassword");
     },1000)
  },
  watch: {

  }
};
</script>
<style lang="less" scoped>
.el-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  :focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
  }

  .el-header {
    width: 100%;
    color: #333;
    text-align: center;
    background: url(../assets/navigationbar/nav.png);
    background-size: 100% 100%;
    background-color: #08222E;
    // border-bottom: 1px solid #4affff;
    overflow: hidden;

    &>div {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      // box-shadow: 2px 2px 5px #000;
      z-index: 2;
    }

    .logo {
      flex: 1px;
      // width: 300px;
      // height: 54px;
      text-align: center;

      .img {
        width: 213px;
        height: 54px;
      }
    }

    .tab {
      width: 29%;
      display: flex;
      align-items: center;

      color: #ffffff;
      font-size: 16px;
      letter-spacing: 2px;

      .tab_box {
        min-width: 100px;
        margin-right: 50px;
        cursor: pointer;

        &>div {
          width: 196px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          background: url(../assets/navigationbar/tab1.png);
          background-size: 100% 100%;
          color: #25c4c2;
        }

        .tab_color {
          color: #fff;
          background: url(../assets/navigationbar/tab.png);
          background-size: 100% 100%;
        }
      }

      .tab_box:last-child {
        margin-right: 0px;
      }

    }

    .tab_left {
      justify-content: right;
    }

    .tab_right {

      justify-content: left;

      .tab_box:nth-child(2) {
        margin-right: 0px;
      }
    }
  }

  .containerleft {
    width: 400px;
    height: 100%;
    background-color: var(--main-group-color);
    color: #333;
    text-align: center;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
  }

  .left_show {
    width: 381px !important;
    height: 100%;
    transition: width 0.5s;
  }

  .left_hide {
    width: 0px !important;
    transition: width 0.5s;
    // width: 0px !important;
  }

  .containerleft_zk {
    width: 54px;
    height: 100%;
    background: url(../assets/open1.png);
    background-size: 100% 100%;
    position: absolute;
    right: -53px;
    z-index: 10;
    display: flex;
    align-items: center;
  }

  .open_img {
    width: 36px;
    height: 275px;
    margin: auto 0px auto auto;
  }

  .right_show {
    width: 398px !important;
    height: 100%;
    transition: width 0.5s;
  }

  .right_hide {
    width: 0px !important;
    transition: width 0.5s;
    // width: 0px !important;
  }

  .containerright {
    width: 398px;
    height: 100%;
    background-color: var(--main-group-color);
    position: relative;
    display: flex;
    align-items: center;
  }

  .containerright_zk {
    width: 54px;
    height: 100%;
    background: url(../assets/open1.png);
    background-size: 100% 100%;
    position: absolute;
    left: -53px;
    top: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    transform: rotate(-180deg);
  }

  .main {
    flex: 1;
    height: auto;
    // overflow: hidden;
    background-color: var(--main-bg-color);
    color: #333;
    text-align: center;
    // line-height: 160px;
    position: relative;
    padding: 0px !important;

    &_broder {
      width: 100%;
      height: 80px;
      // border-bottom: 1px solid #034050;
      position: relative;
      z-index: 1;
    }

    &_history {
      padding: 0px 10px 0px 20px;
      margin-bottom: 20px;

      &_box {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
      }
    }

    &_height{
      height: calc(100% - 110px);
    }
  }
}

.baidu-api {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
}

.position_relative {
  position: relative;
}
</style>
