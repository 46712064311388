<template>
    <!-- <div class="box flex"> -->
    <el-dialog title="修改密码" v-if="dialogModifyPasswode" :append-to-body="true" :visible.sync="dialogModifyPasswode" width="670px"
        :before-close="handleClose" center>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <div class="content">
                <div style="height:40px;"></div>
                <el-form-item label="旧密码 :" prop="usedPassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" placeholder="请输入旧密码"
                        v-model="ruleForm.usedPassword"></el-input>
                    <!-- autocomplete="off" -->
                    <div class="content_text"></div>
                </el-form-item>
                <el-form-item label="新密码 :" prop="newPassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" placeholder="请输入新密码"
                        v-model="ruleForm.newPassword"></el-input>
                    <div class="content_text">*由8-16位包含字母、数字、特殊符号组成</div>
                </el-form-item>
                <el-form-item label="确认密码 :" prop="determinePassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" placeholder="确认输入新密码"
                        v-model="ruleForm.determinePassword"></el-input>
                    <div class="content_text"></div>
                </el-form-item>
            </div>
            <div class="bottom">
                <el-button class="bc" @click="submitForm('ruleForm')">保存</el-button>
                <el-button class="gb" @click="resetForm('ruleForm')">关闭</el-button>
            </div>
        </el-form>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import regfunc from "../method/form/regfunc.js";
import EventBus from "@/eventBus/eventBus.js";
import { updatePassword } from "@/administration/login.js";
export default {
    props: {
        // dialogModifyPasswode: {
        //     type:Boolean,
        //     default:true
        // } 
    },
    data() {
        var usedPassword = (rule, value, callback) => {     //旧密码
            if (value == "") {
                callback(new Error('请输入旧密码'));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else if (!regfunc.rPassword.test(value)) {
                callback(new Error('*由8-16位包含字母、数字、特殊符号组成'));
            } else if (this.ruleForm.usedPassword === value) {
                callback(new Error('旧密码与新密码不能一致！'));
            } else {
                callback();
            }
        };
        var determinePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                usedPassword: '',       //旧密码
                newPassword: '',        //新密码
                determinePassword: ''   //确定密码
            },
            rules: {
                usedPassword: [
                    { validator: usedPassword, trigger: 'blur' }
                ],
                newPassword: [
                    { validator: newPassword, trigger: 'blur' }
                ],
                determinePassword: [
                    { validator: determinePassword, trigger: 'blur' }
                ]
            },
            dialogModifyPasswode: false
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        openKq() {       //开启
            this.dialogModifyPasswode = true;
        },
        handleClose() {     //关闭
            this.dialogModifyPasswode = false;
        },
        submitForm(formName) {      //修改密码
            let that = this;
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = {
                        uId: userInfo.id,
                        oldPassword: that.ruleForm.usedPassword,
                        password: that.ruleForm.newPassword
                    }
                    updatePassword(data).then(res => {
                        console.log(res)
                        if (res.data.code == 200) {
                            // localStorage.removeItem("userNumber");
                            sessionStorage.removeItem("userInfo");
                            localStorage.removeItem("userNumber");
                            // this.$router.push("/Login");
                            EventBus.$emit('changePassword');
                            that.dialogModifyPasswode = false;
                            // this.$message({
                            //     message: '修改成功！',
                            //     type: 'success'
                            // });
                            that.$MessageSuccess('修改成功！')
                        } else {
                            that.$message.error(res.data.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogModifyPasswode = false;
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        }
    },
    mounted() {

    },
    watch: {
        //监听事件
        dialogModifyPasswode(show) {
            if (show) {
                this.ruleForm = {
                    usedPassword: '',       //旧密码
                    newPassword: '',        //新密码
                    determinePassword: ''   //确定密码
                }
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-dialog {
    background: #0F3343;
    margin-top: 20vh !important;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }



    .el-input__inner {
        width: 195px;
        height: 35px;
        background-color: #08222E;
        border: 1px solid #07415A;
        color: #fff;
    }

}

/deep/.el-input {
    width: 195px;
}

/deep/.gb.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid #fff !important;
    background-color: transparent !important;
    color: #fff !important;
}

/deep/.bc.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid #4AFFFF !important;
    background-color: transparent !important;
    color: #4AFFFF !important;
    margin-right: 30px;
}

/deep/.el-form-item__error {
    padding-top: 0px !important;
}

.content {
    width: 90%;
    height: 251px;
    margin: 0px auto 0px auto;
    // text-align: center;
    background-color: #08222E;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_text {
        display: inline-block;
        width: 230px;
        color: red;
        font-size: 12px;
        margin-left: 10px;
    }

    /deep/.el-form-item {
        margin-bottom: 15px;

        &__label {
            text-align: center;
            color: #fff !important;
        }
    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

.flex {
    display: flex;
    align-items: center;
}
</style>