<template>
  <div id="box">
    <div class="box_one flex_column">
      <!-- 导航栏 -->
      <div class="nav">
        <!-- 调度对讲 -->
        <div class="nav_box flex_align">
          <div :class="['nav_name', index == navIndex ? 'nav_name_active' : '']" v-for="(item, index) in navArray"
            :key="item.name" @click="navTab(index)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 导航栏结束 -->
      <!-- 筛选 -->
      <div class="screen">
        <div class="screen_box flex_align">
          <div>筛选 :</div>
          <el-select v-model.trim="screen" placeholder="全部" @change="changeScreen">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="@/assets/group/localGroup.png" @click="regionGroup" alt="" />
      </div>
      <!-- 筛选结束 -->
      <!-- 搜索 -->
      <div class="search flex_align">
        <div class="search_input flex_align">
          <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
          <!-- reserve-keyword -->
          <!-- <el-select style="width: 100%" v-model="search" filterable remote placeholder="群组/用户"
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in optionsArray" :key="item.id" :label="item.label" :value="item.label">
            </el-option>
          </el-select> -->
          <!-- @input="remoteMethod" -->
          <el-input v-model="search" @focus="focus" @blur="blur" placeholder="群组/用户"></el-input>
          <img class="mouse" src="@/assets/group/gb1.png" @click="query(1)" alt="" />
        </div>
        <div class="search_but mouse" @click="query()">
          <img src="@/assets/group/search1.png" alt="" />
        </div>
      </div>
      <!-- 搜索结束 -->
      <!-- 树级控件 -->
      <div class="treeLevel flex_grow" id="treeLevel" ref="treeLevel"
        :style="{ overflow: $store.state.prohibitRoll ? 'hidden' : 'auto' }">
        <!-- lazy :load="loadNode" -->
        <el-tree v-if="openPanel" :data="groupArray" show-checkbox @check="check" node-key="key" ref="tree"
          :highlight-current="isHighlight" :props="defaultProps" :disabled="false" @node-expand="handleNodeExpand"
          @node-collapse="collapse" :expand-on-click-node="false" :current-node-key="choiceNameData.key"
          :default-checked-keys="checkedArray" :default-expanded-keys="expandedKeys">
          <div class="flex_grow" style="width:100%;" slot-scope="{ node, data }" ref="element">
            <el-popover popper-class="my_popover" transition="el-collapse-transition" placement="right-start"
              @show="onmouseover(data)" @hide="onmouseout(data)" :open-delay="500" :offset="-3" :close-delay="200"
              trigger="hover" :visible-arrow="false">
              <div class="treeLevel_popup mouse prohibitCopy" v-show="!data.children">
                <div class="treeLevel_popup_box flex_between">
                  <div>语音对讲</div>
                  <el-switch style="margin-left: 15px" @change="changeUpData(0, data)" v-model="data.audioEnabled"
                    :inactive-value="false" :active-value="true" active-color="#4affff" inactive-color="#838383">
                  </el-switch>
                </div>
                <div class="treeLevel_popup_box flex_between">
                  <div>基站开关</div>
                  <el-switch style="margin-left: 15px" @change="changeUpData(1, data)"
                    v-model="data.celllocation_switch" :inactive-value="false" :active-value="true"
                    active-color="#4affff" inactive-color="#838383">
                  </el-switch>
                </div>
                <div class="treeLevel_popup_box flex_between" v-if="data.services && data.services.gps">
                  <div>北斗/GPS开关</div>
                  <el-switch style="margin-left: 15px" @change="changeUpData(2, data)" v-model="data.GPSswitch"
                    :inactive-value="false" :active-value="true" active-color="#4affff" inactive-color="#838383">
                  </el-switch>
                </div>
                <div class="treeLevel_popup_ms flex_align" v-if="data.services && data.services.gps && data.GPSswitch">
                  <div class="treeLevel_popup_ms_num" v-for="(item, index) in secondArray" :key="index" :class="item.name == data.locPeriod
            ? 'treeLevel_popup_ms_num_red'
            : ''
            " @click="bindQh(data, index)">
                    {{ item.name }}s
                  </div>
                </div>
                <div style="padding-top: 15px" @click="edit(data, 3)">
                  查看历史轨迹
                </div>
              </div>
              <!-- 用户状态结束 -->
              <!-- 编辑 -->
              <div class="treeLevel_popup mouse prohibitCopy" style="padding:0px;"
                v-show="data.children && data.GroupType == 1">
                <div class="treeLevel_popup_bg" @click="edit(data, 1)">
                  编辑
                </div>
              </div>
              <!-- 区域群组编辑 -->
              <div class="treeLevel_popup mouse prohibitCopy" style="padding:0px;"
                v-show="data.children && data.GroupType == 4">
                <div class="treeLevel_popup_bg" @click="regionEdit(data, 1)">
                  编 辑
                </div>
                <div class="treeLevel_popup_bg" @click="regionEdit(data, 2)">
                  修改区域
                </div>
                <div class="treeLevel_popup_bg" @click="regionEdit(data, 3)">
                  删 除
                </div>
              </div>
              <!-- 编辑 -->
              <template slot="reference">
                <div class="treeLevel_tree">
                  <div class="treeLevel_left flex_align">
                    <div v-show="!data.children" style="height: 16px;">
                      <img class="userimgw" style="margin-right: 5px" :src="upUserImg(data, 1)" alt="" />
                    </div>
                    <div v-html="eachColor(node.label, search1)" @click.prevent="choiceName(data)"
                      :style="data.children ? '' : 'flex: 1;'" class="treeLevel_left_label" :class="upLabelColor(data)">
                      {{ node.label }}
                    </div>
                    <div v-if="data.children" class="treeLevel_left_total">
                      <span style="color: #4affff">{{ data.num }}</span>/{{ data.total }}
                    </div>
                  </div>
                  <!-- 编辑 -->
                  <div class="treeLevel_right flex_align" :id="data.key" v-show="data.children">
                    <span :class="['group_jr', data.userState ? 'group_jr_cor' : '']" @click="getInto(data)">进入</span>
                    <div :style="data.GroupType == 2 ? 'visibility: hidden;' : ''">
                      <img class="userimgw userimgw_left" @click="bindMonitorGroup('monitor', 2, data)"
                        v-if="data.isMonitor" src="@/assets/group/monitor1.png" alt="" />
                      <img class="userimgw userimgw_left" @click="bindMonitorGroup('monitor', 1, data)" v-else
                        src="@/assets/group/monitor.png" alt="" />
                      <img class="userimgw userimgw_left" @click="bindMonitorGroup('radiobroadcast', 2, data)"
                        v-if="data.isRadioBroadcast" src="@/assets/group/radiobroadcast1.png" alt="" />
                      <img class="userimgw userimgw_left" @click="bindMonitorGroup('radiobroadcast', 1, data)" v-else
                        src="@/assets/group/radiobroadcast.png" alt="" />
                    </div>
                  </div>
                  <!-- 服务图标 -->
                  <div class="treeLevel_right flex_align" v-show="!data.children">
                    <span v-html="upUserImg(data, 2)"></span>
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
        </el-tree>
      </div>
    </div>
    <!-- 通话弹出框 -->
    <dhPopup ref="dhPopup" v-if="false"></dhPopup>
    <!-- 编辑 -->
    <dialogbox ref="dialogbox" :dialogTitle="dialogTitle" :dialogData="userData" :butName="butName"
      :cancelBut="cancelBut" :bgShow="bgShow" :timeShow="timeShow" :width="dialogWidth"></dialogbox>
    <editGroup v-if="isOpen" :regionId="regionId" :Gid="Gid"></editGroup>
  </div>
</template>

<script>
import { _debounce } from "@/method/utils.js";
import dhPopup from "@/components/dispatchLntercom/dhPopup.vue";
import dialogbox from "@/components/dispatchLntercom/dialogbox.vue";
import EventBus from "@/eventBus/eventBus.js";
import editGroup from "@/components/dispatchLntercom/editGroup.vue";
import {
  queryUserService,
  updateUserStatus,
  getRegionId
} from "@/administration/dispatchLntercom.js";
import axios from "axios";
import beasconfig from "@/api/beasconfig.js";
import userImg from "@/method/userImg.js";
const regionUrlapi = beasconfig.regionUrlapi;
export default {
  components: {
    dhPopup,
    dialogbox,
    editGroup
  },
  props: {

  },
  data() {
    return {
      navArray: [
        {
          id: 1,
          name: "全部群组"
        },
        {
          id: 2,
          name: "固定群组"
        },
        {
          id: 3,
          name: "区域群组",
        }
      ],
      navIndex: 0, //群组tab下标
      options: [
        {
          //筛选类型
          value: "-1",
          label: "全部"
        },
        {
          value: "3",
          label: "在线"
        },
        {
          value: "1",
          label: "离线"
        }
      ],
      defaultProps: {
        children: "children",
        label: "label",
        isLeaf: 'isBool'
      },
      screen: "",
      ddGroupArray: [
        //调度对讲群组底部方法
        {
          id: 1,
          name: "通话",
          url: require("@/assets/group/group1.png"),
        },
        {
          id: 2,
          name: "呼叫",
          url: require("@/assets/group/group2.png")
        },
        {
          id: 3,
          name: "强拉",
          url: require("@/assets/group/group3.png")
        },
        {
          id: 4,
          name: "强拆",
          url: require("@/assets/group/group4.png")
        }
      ],
      search: "",
      search1: "",
      loading: false,
      optionsArray: [],
      list: [],
      secondArray: [{ name: "10" }, { name: "30" }, { name: "60" }], //秒數
      secondIndex: null,
      userInfo: {},
      userData: {}, //用户数据
      choiceData: [], //选中的数据
      checkedArray: [],
      dialogWidth: "960px",
      bgShow: false,
      timeShow: false,
      dialogTitle: "",
      butName: "",
      cancelBut: "",
      groupArray: [],
      expandedKeys: [],
      isQuery: false,
      choiceNameData: "",
      timeDate: {
        isShow: false
      },
      top: 0,
      isHighlight: false,
      isOpen: false,    //是否显示区域群组编辑
      regionId: 0,
      openPanel: true,
      Gid: 0   //群组id
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  beforeDestroy: function () {
    EventBus.$off("successMsg");
    EventBus.$off("nowSpeech");
    EventBus.$off("selectKey");
    EventBus.$off("screenData");
    EventBus.$off("updateData");
    EventBus.$off("groupMethod");
  },
  mounted() {
    let that = this;
    EventBus.$on("successMsg", function () {
      //语音对讲
      that.choiceData = []; //选中的数据
      that.checkedArray = [];
    });
    EventBus.$on("nowSpeech", function (uid, gid, bool) {
      //正在讲话
      that.nowSpeech(uid, gid, bool);
    });
    EventBus.$on("selectKey", function () {
      //强制选中
      that.selectKey();
    });
    EventBus.$on("screenData", function (data) {
      that.screenData(data);
    });
    EventBus.$on("updateData", function (sosInfo, typeName) {    //更新单独一条服务数据
      let groupArray = that.groupArray;
      sosInfo = JSON.parse(sosInfo);
      if (typeName == "monitorMsg") {
        groupArray.forEach((item, index) => {
          let bool = sosInfo.some(row => row == item.Gid);
          if (bool) {
            that.$set(that.groupArray[index], 'isMonitor', true);
          } else {
            that.$set(that.groupArray[index], 'isMonitor', false);
          }
        })
        that.refreshTree();
        that.$store.state.groupUserData = groupArray;
        return
      }
      function into(f) {
        if (f < groupArray.length) {
          let i =
            groupArray.length > 0
              ? groupArray[f].children.findIndex(
                item => item.id == sosInfo.user_id
              )
              : -1;
          if (i >= 0) {
            let changeChild = {
              ...groupArray[f].children[i],
              services: sosInfo.services,
              GPSswitch: sosInfo.user_gpsswitch == 1 ? true : false,
              locPeriod: sosInfo.user_gpsfrequency,
              celllocation_switch: sosInfo.celllocation_switch,
              audioEnabled: sosInfo.user_audiostatus == 0 ? false : true
            };
            that.$set(that.groupArray[f].children, i, changeChild);
          }
          into(f + 1);
        } else {
          that.refreshTree();
          that.$store.state.groupUserData = groupArray;
        }
      }
      into(0);
    });
    EventBus.$on("groupMethod", function (row) {
      //强制选中
      that.groupMethod(row);
    });
  },
  methods: {
    eachColor(content, searchVal) {
      if (content == undefined) {

        return
      }
      let nt = '<span style="color:#4affff;">' + searchVal + "</span>";
      return content.replace(searchVal, nt) + "&nbsp;";
    },
    upLabelColor(row) {
      let text = '';
      if (this.choiceNameData.id == row.id || row.queryState) {
        text = 'blue';
      } else if (row.mmcount > 0 && row.GroupType != 2) {
        text = 'yelow2';
      } else if (row.GroupType == 2) {
        text = 'yelow';
      } else if (row.GroupType == 4) {
        text = 'blue_qs';
      }
      return text;
    },
    upUserImg(data, type) {  //用户对讲图标

      return type == 1 ? userImg.upUserImg(data) : userImg.userServeImg(data);
    },
    changeScreen(e) {
      //筛选数据
      let groupUserData = JSON.parse(
        JSON.stringify(this.$store.state.groupUserData)
      );
      groupUserData.forEach(item => {
        if (e == 3) {
          item.num = item.children.filter(item1 => item1.online == 3).length;
          item.children = item.children.filter(item1 => item1.online == 3);
        } else if (e == 1) {
          item.num = 0;
          item.children = item.children.filter(item1 => item1.online == 1);
        } else {
          item.num = item.children.filter(item1 => item1.online == 3).length;
        }
      });
      if (this.navIndex == 1) {
        groupUserData = groupUserData.filter(item => item.GroupType == 1);
      } else if (this.navIndex == 2) {
        groupUserData = groupUserData.filter(item => item.GroupType == 4);
      }
      this.groupArray = groupUserData.filter(item => item.mmingroup != 1);
      this.$store.state.onlineStatus = e;
      this.search = "";
      this.search1 = "";
      this.screen = e;
      this.expandedKeys = [];
      this.isQuery = true;
      this.checkedArray = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
      this.rollBackTree(1);
    },
    edit(row, type) {
      //编辑
      let that = this;
      if (type == 1) {
        that.bgShow = true;
        that.timeShow = false;
        // that.dialogWidth = "50%";
        that.dialogTitle = "编辑";
        that.butName = "保存";
        that.cancelBut = "取消";
      } else {
        that.bgShow = false;
        that.timeShow = true;
        // that.dialogWidth = "50%";
        that.dialogTitle = "选择时间";
        that.butName = "确定";
        that.cancelBut = "关闭";
      }
      that.userData = row;
      setTimeout(() => {
        that.$refs.dialogbox.openPg(type, row);
      }, 200);
    },
    regionEdit(row, type) {
      getRegionId({ groupId: row.Gid }).then(res => {
        if (res.data.code == 200) {
          if (!res.data.data) {
            this.$MessageWarning('此群组已被删除，无法操作！');
            EventBus.$emit("strongBrushList");
            return
          }
          row.regionId = res.data.data.region_id;
          this.regionId = res.data.data.region_id;
          if (type == 1) {
            this.Gid = row.Gid;
            this.isOpen = false;
            setTimeout(() => {
              this.isOpen = true;
            }, 300)
          }
          if (type == 2) {
            this.choiceNameData = "";
            EventBus.$emit('editEnclosureMap', row, 1)
          }
          if (type == 3) {
            this
              .$confirm("确定要删除区域群组吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              })
              .then(() => {
                this.deleteRegionGroup(row);
              })
              .catch(() => { });
          }
        } else {
          this.$MessageWarning(res.data.msg);
        }
      })
    },
    refreshTree() {    //刷新树     懒加载会导致重复数据所以需要重新创建节点
      this.openPanel = false;
      this.$nextTick(() => {
        this.openPanel = true;
      })
    },
    deleteRegionGroup(data) {  //删除区域群组
      axios.delete(`${regionUrlapi}-${this.$store.state.userState.server}/region/${data.regionId}`)
        .then(res => {
          if (res.data.code == 200) {
            let array = [];
            let regionGroup = JSON.parse(sessionStorage.getItem("regionGroup"));
            if (regionGroup) {
              array = regionGroup
            }
            array.push(data.Gid);
            sessionStorage.setItem("regionGroup", JSON.stringify(array));
            let i = this.$store.state.groupUserData.findIndex(item => item.Gid == data.Gid);
            let index = this.groupArray.findIndex(item => item.Gid == data.Gid);
            this.groupArray.splice(index, 1);
            this.$store.state.groupUserData.splice(i, 1);
            this.$store.state.recordAddRegionNum -= 1;
            if (this.choiceNameData != "") {
              this.choiceNameData = "";
            }
            this.refreshTree();
            EventBus.$emit("emptyMap", 2);
          } else {
            this.$MessageWarning(res.data.msg);
          }
        })
    },
    check(row, tree) {
      //选中树级菜单的值
      this.choiceData = tree.checkedNodes;
      this.checkedArray = tree.checkedNodes
        .filter(item => item.online == 3)
        .map(item => item.key);
      sessionStorage.setItem("checkedArray", JSON.stringify(this.checkedArray)); //缓存选中的值
    },
    bindMonitorGroup(name, type, data) {
      //监听群组
      let f = this.groupArray.findIndex(item => item.Gid == data.Gid);
      let n = this.$store.state.groupUserData.findIndex(item => item.Gid == data.Gid);
      if (name == "monitor") {
        this.groupArray[f].isMonitor = !this.groupArray[f].isMonitor;
        EventBus.$emit("bindMonitorGroup", name, type, data);
      } else {
        this.groupArray[f].isRadioBroadcast = type == 1 ? true : false;
        this.$store.state.groupUserData[n].isRadioBroadcast = type == 1 ? true : false;
        let arr = this.groupArray
          .filter(item => item.isRadioBroadcast == true)
          .map(item => item.key);
        sessionStorage.setItem("radioBroadcastState", JSON.stringify(arr));
      }
      sessionStorage.setItem("groupArray", JSON.stringify(this.groupArray));
    },
    // 切换标签卡
    navTab(index) {
      if (this.navIndex == index) {
        return;
      }
      this.navIndex = index;
      this.groupArray = [];
      this.screen = "";
      this.$store.state.onlineStatus = "";
      this.search = "";
      this.search1 = "";
      this.checkedArray = [];
      this.expandedKeys = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
      this.rollBackTree(1);
    },
    groupMethod(item) {
      //左侧切换内容
      //组方法
      let choiceData = this.choiceData;
      let online = this.deweight(choiceData);
      if (this.choiceData.length == 0) {
        this.$MessageWarning(`请选择需要${item.name}的用户！`);
        return;
      }
      if (item.id == 1) {
        //通话
        // this.$refs.dhPopup.openPhone();
        if (this.$store.state.voiceCallInfo.callType != 0) {
          this.$MessageWarning('正在语音通话！');
          return
        }
        if (this.$store.state.videoCallInfo.callType != 0) {
          this.$MessageWarning('正在视频通话！');
          return
        }
        if (this.$store.state.userState.fullDuplex == 0) {
          this.$MessageWarning('请先在管理平台开启语音通话功能！');
          return
        }
        let groupUserData = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
        let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);   //判断当前群组列表是否有临时群组
        let temporaryGroupTow = temporaryGroup.some(item => this.$store.state.soketUserInfo.gid == item.Gid);
        if (temporaryGroup && temporaryGroup.length > 0 && temporaryGroupTow) {
          this.$MessageWarning(`已在临时群组，无法呼叫!`);
          return
        }
        if (online.length > 1) {
          this.$MessageWarning(`只支持对单个用户发起语音通话!`);
          return
        }
        queryUserService({
          uId: this.userInfo.id,
          uids: online.map(item => item.id).join(',')
        }).then(res => {
          let bool = res.data.data.some(item => item.full_duplex == 0);
          if (bool) {
            this.$MessageWarning('此用户未在管理平台开启语音通话功能！');
          } else {
            this.$store.state.voiceCallInfo.callName = online[0].label;
            EventBus.$emit("startCall", online.map(item => item.id));
          }
        });
        // return
      }
      if (item.id == 2 && this.$store.state.voiceCallInfo.callType != 0) {
        this.$MessageWarning('正在通话中，无法创建！');
        return
      }
      if (item.id == 2 && this.$store.state.audio_enable != 1) {
        this.$MessageWarning(`摇毙中，不可创建`);
        return
      }
      if (item.id != 1) {
        let array = online.filter(item => item.online == 3);
        let obj = {
          ids: array.map(item => item.id),
          row: array
        };
        EventBus.$emit("menuOption", item.id, obj);
      }
      this.choiceData = []; //选中的数据
      this.checkedArray = [];
      this.$refs.tree.setCheckedKeys([]);
      sessionStorage.removeItem("checkedArray");
      this.refreshTree();
    },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    deweightOne(arr) {
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j != i);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    deweightTow(arr) {
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.Gid != i.Gid);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    query: _debounce(function (type) {
      //查询方法
      let groupArray = JSON.parse(
        JSON.stringify(this.groupArray)
      );
      // this.groupArray
      if (type == 1) {
        this.search = "";
        this.search1 = "";
        this.rollBackTree(1);
        // return
      }
      this.isQuery = true;
      this.checkedArray = []; //清空选中的值
      this.expandedKeys = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
      if (this.search !== "") {
        groupArray.forEach(item => {
          if (
            item.label.indexOf(this.search) > -1
          ) {
            //先检索是否是群组
            this.expandedKeys.push(item.key);
            if (item.children.length > 0) {
              this.handleNodeExpand(item, item, 1);
            }
          }
          item.children.filter((itemOne, index) => {
            if (
              itemOne.label.indexOf(this.search) >
              -1
            ) {
              let bool = this.expandedKeys.some(itemTow => itemTow == item.key);
              if (!bool) {  //判断相同的则不进入
                this.expandedKeys.push(item.key);
                this.handleNodeExpand(item, item, 1);
              }
              if (itemOne.label == this.search) {
                item.children[index].queryState = true;
              }
            }
          });
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
        this.search1 = this.search;
        this.expandedKeys = this.deweightOne(this.expandedKeys);
        sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
        this.rollBackTree();
      } else {
        this.search1 = "";
        groupArray.forEach(item => {
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
      }
      groupArray = groupArray.filter(item => item.mmingroup != 1);
      if (this.navIndex == 0) {
        this.groupArray = groupArray;
      } else if (this.navIndex == 1) {
        this.groupArray = groupArray.filter(item => item.GroupType == 1);
      } else {
        this.groupArray = groupArray.filter(item => item.GroupType == 4);
      }
      this.$forceUpdate();
    }, 200),
    rollBackTree(type) {
      if (type == 1) {
        this.$refs.treeLevel.scrollTop = 0;
      } else {
        const node = document.getElementById(this.expandedKeys[0])
        node.scrollIntoView({ block: "start" })
      }
    },
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
    arrayMerge() {
      //数据合并
      let groupUserData = this.$store.state.groupUserData;
      let array = [];
      groupUserData.forEach(item => {
        item.children.filter(itmeOne => {
          array.push(item, itmeOne);
        });
      });
      return this.deweight(array);
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        resolve(node.data);
        return
      }
      if (node.data.children.length <= 0) {
        resolve([]);
        return
      }
      let bool = node.data.children.every(item => item.services);
      if (bool) {     //判断服务有没有，有则不掉接口
        // setTimeout(() => {
        const data = node.data.children;
        resolve(data);
        // }, 500);
        return
      }
      let i = this.groupArray.findIndex(item => item.Gid == node.data.Gid);
      let groupUserData = this.$store.state.groupUserData;
      let f = groupUserData.findIndex(r => r.Gid == node.data.Gid);
      let array = node.data.children.map(item => item.id).join(); //集合id
      await queryUserService({
        uId: this.userInfo.id,
        uids: array
      }).then(res => {
        res.data.data.forEach(item => {
          node.data.children.forEach((item1, index) => {
            if (item.User_ID == item1.id && !item1.services) {
              this.$set(this.groupArray[i].children, index, {
                ...item1,
                ...item
              });
            }
          });
          groupUserData[f].children.forEach((r, m) => {     //附加数据防止报错
            if (item.User_ID == r.id && !r.services) {
              this.$set(this.$store.state.groupUserData[f].children, m, {
                ...r,
                ...item
              });
            }
          })
        });
      });
      const data = node.data.children;
      resolve(data);
    },
    //
    async handleNodeExpand(row, tree, type) {
      if (type != 1) {
        this.expandedKeys.push(tree.key);
      }
      //展开调用数据
      let i = this.groupArray.findIndex(item => item.id == row.id);
      let array = row.children.map(item => item.id).join(); //集合id
      let bool = row.children.every(item => item.services);
      if (!bool) {     //判断服务有没有，有则不掉接口
        // 获取成员服务状态
        await queryUserService({
          uId: this.userInfo.id,
          uids: array
        }).then(res => {
          res.data.data.forEach(item => {
            row.children.forEach((item1, index) => {
              if (item.User_ID == item1.id) {
                // setTimeout(() => {
                this.$set(this.groupArray[i].children, index, {
                  ...item1,
                  ...item
                });
                // }, 100)
              }
            });
          });
        });
      }
      this.isHighlight = false;
      sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
      sessionStorage.setItem("groupArray", JSON.stringify(this.groupArray));
      sessionStorage.setItem("checkedArray", JSON.stringify(this.checkedArray)); //缓存选中的值
      await this.$refs.tree.setCheckedKeys(this.checkedArray);
    },
    collapse(row, tree) {
      //收起
      // console.log(row, tree);
      this.isHighlight = false;
      let index = this.expandedKeys.findIndex(item => item == tree.data.key);
      this.expandedKeys.splice(index, 1);
      sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
    },
    changeUpData(type, row) {
      //修改用户状态
      if (type == 1) {
        let data = {
          uid: row.id,
          audioStatus: row.audioEnabled ? 1 : 0,
          gpsSwitch: row.GPSswitch ? 1 : 0,
          gpsFrequency: row.locPeriod,
          celllocationSwitch: row.celllocation_switch ? 1 : 0
        };
        updateUserStatus(data).then(res => {
          if (res.data.code == 200) {
            this.changeDataStatus(type, row);
          } else {
            this.$MessageWarning(res.data.msg);
          }
        });
      } else {
        EventBus.$emit("upData", type, row);
        this.changeDataStatus(type, row);
      }
    },
    changeDataStatus(type, row) {
      //更改数据状态
      let that = this;
      let children = that.groupArray;
      function into(f) {
        if (f < children.length) {
          let i = children[f].children.findIndex(item => item.id == row.id);
          if (i >= 0) {
            if (type == 0) {
              that.$set(
                children[f].children[i],
                "audioEnabled",
                row.audioEnabled
              ); //语音
            }
            if (type == 1) {
              that.$set(
                children[f].children[i],
                "celllocation_switch",
                row.celllocation_switch
              ); //基站
            }
            if (type == 2) {
              that.$set(children[f].children[i], "GPSswitch", row.GPSswitch); //gps
              that.$set(children[f].children[i], "locPeriod", row.locPeriod);
            }
          }
          into(f + 1);
        } else {
          sessionStorage.setItem("groupArray", JSON.stringify(that.groupArray));
        }
      }
      into(0);
    },
    choiceName(data) {
      //选中数据
      if (this.choiceNameData.id == data.id) {
        this.choiceNameData = "";
        EventBus.$emit("changeMapOne", "", 0);
        // this.$refs.tree.setCurrentKey(null)
      } else {
        this.choiceNameData = data;
        let type = data.children ? 2 : 1;
        let find = type == 1 ? this.groupArray.find(item => item.Gid == data.gid) : null;
        if (data.GroupType == 4 || find && find.GroupType == 4) {
          if (find) {
            this.choiceNameData.Gid = data.gid;
          }
          this.querGetRegionId(type);
        } else {
          EventBus.$emit("changeMapOne", this.choiceNameData, type);
        }
        // this.$refs.tree.setCurrentKey(data.key)
      }
      this.isHighlight = false;
    },
    querGetRegionId(type) {
      getRegionId({ groupId: this.choiceNameData.Gid }).then(res => {
        if (res.data.code == 200) {
          if (!res.data.data) {
            this.$MessageWarning('此群组已被删除，无法操作！');
            this.choiceNameData = "";
            EventBus.$emit("strongBrushList");
            return
          }
          this.choiceNameData.regionId = res.data.data.region_id;
          EventBus.$emit("changeMapOne", this.choiceNameData, type);
        } else {
          this.$MessageWarning(res.data.msg);
        }
      })
    },
    onmouseover(data) {
      this.$refs.tree.setCurrentKey(data.key);
    },
    onmouseout() {
      //鼠标离开触发
      this.$refs.tree.setCurrentKey(null);
    },
    bindQh(data, index) {
      // return
      //选择频率
      let children = this.groupArray;
      var f = children.findIndex(item => item.id == data.gid);
      var i = children[f].children.findIndex(item => item.id == data.id);
      this.groupArray[f].children[i].locPeriod = this.secondArray[index].name;
      EventBus.$emit("upData", 2, data);
    },
    getInto(data) {
      //进入群组
      if (this.$store.state.audio_enable != 1) {
        this.$MessageWarning(`摇毙中，不可切换群组`);
        return
      }
      let index = this.groupArray.findIndex(item => item.Gid == data.Gid);
      let i = this.groupArray.findIndex(item => item.userState == true);
      if (i == -1) {
        this.groupArray[index].userState = true;
      } else {
        this.groupArray[i].userState = false;
        this.groupArray[index].userState = true;
      }
      EventBus.$emit("getInto", data);
      EventBus.$emit("closeConversation");
    },
    deleteDeploymentCache() {
      //删除缓存
      sessionStorage.removeItem("openArray");
      sessionStorage.removeItem("checkedArray");
    },
    nowSpeech(uid, gid, bool) {
      //正在讲话
      let groupArray = this.groupArray;
      let f = groupArray.findIndex(item => item.Gid == gid);
      if (f == -1) {
        return;
      }
      let i = groupArray[f].children.findIndex(item => item.id == uid);
      if (f >= 0 && i >= 0) {
        this.groupArray[f].children[i].isStartSpeech = bool;
      }
    },
    selectKey() {
      //强制选中
      let checkedArray = JSON.parse(sessionStorage.getItem("checkedArray"));
      if (checkedArray) {
        this.checkedArray = checkedArray;
        setTimeout(() => {
          this.$refs.tree.setCheckedKeys(this.checkedArray);
        }, 50);
      }
    },
    regionGroup() {    //  区域群组
      // let num = this.groupArray.filter(item => item.GroupType == 4).length;
      // num >= 3 || 
      if (this.$store.state.recordAddRegionNum >= 200) {
        this.$MessageWarning('最多只能创建200个区域群组!');
        return
      }
      this.choiceNameData = "";
      this.$customMessageError('请先搜索或自圈选区域！');
      this.$store.state.efRemark = '1';
      EventBus.$emit("emptyMap", 0);
    },
    screenData(data) {
      //筛选数据
      this.refreshTree();
      let arr = JSON.parse(data);
      let array = [];
      if (arr && arr.length > 0) {
        //是否有传参
        array = this.deweightTow(arr);
      } else {
        array = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      }
      if (array.length > 0) {
        array = array.filter(item => item.mmingroup != 1);
        array.forEach(item => {
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
        if (this.navIndex == 1) {
          this.groupArray = array.filter(item => item.GroupType == 1);
        } else if (this.navIndex == 2) {
          this.groupArray = array.filter(item => item.GroupType == 4);
        } else {
          this.groupArray = array;
        }
      }
      if (this.choiceData.length > 0) {   //防止临时群组退组还是勾选状态
        this.$store.state.groupUserData.forEach(() => {
          this.choiceData.forEach((item, index) => {
            let id = item.Gid ? item.Gid : item.gid
            let find = this.$store.state.groupUserData.find(r => r.Gid == id);
            if (!find) {
              this.choiceData.splice(index, 1);
            }
          })
        })
      }
      this.refreshTree();
    }
  },
  watch: {
    //监听事件
    "$store.state.groupUserData": {
      handler(newValue) {
        let openArray = JSON.parse(sessionStorage.getItem("openArray"));
        // this.refreshTree();
        if (openArray) {
          this.expandedKeys = openArray;
          this.selectKey();
        } else {
          if (newValue.length > 0) {
            this.screenData(JSON.stringify(this.$store.state.groupUserData));
          }
        }
      },
      // deep: true,
      immediate: true
    },
    navIndex(navIndex) {
      let array = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      array = array.filter(item => item.mmingroup != 1);
      if (navIndex == 1) {
        this.groupArray = array.filter(item => item.GroupType == 1);
      } else if (navIndex == 2) {
        this.groupArray = array.filter(item => item.GroupType == 4);
      } else {
        this.groupArray = array;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url(../../css/scrollbar.css);

#box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--main-group-color);

  .box_one {
    width: calc(100% - 24px);
    height: 100%;
    position: relative;
    top: 10px;
    margin: auto;
  }

  /deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content {
      height: 35px;
      font-size: 14px;
      font-weight: bold;

      &>.el-tree-node__expand-icon {
        font-size: 20px;
        padding: 0px 5px 0px 0px !important;
        margin-left: -5px;
        color: #00FDFF;
      }
    }

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
      background-color: #043846 !important;
    }

    // .el-tree-node:focus,
    // .is-expanded:focus,
    // .is-focusable:focus {
    //   background-color: #043846 !important;
    // }

    .el-tree-node.is-current>.el-tree-node__content {
      background: #043846;
    }

    .el-tree--highlight-current.el-tree-node.is-current>.el-tree-node__content {
      background: transparent;
    }

    .el-tree .el-tree-node.is-current>.el-tree-node__content {
      background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
      color: #00FDFF;
    }

    .el-tree-node__expand-icon {
      color: #00FDFF;
      font-size: 18px;
      transition: none!important;   //去除动画避免有抖动
    }

    .el-tree-node__expand-icon.is-leaf {
      color: transparent !important;
      cursor: default;
    }

    // 禁用的复选框颜色
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: var(--main-bg-color) !important;
      border-color: #848484 !important;
    }

    .el-tree-node__content>label.el-checkbox {
      margin-right: 10px !important;
    }

    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      background-color: var(--main-bg-color);
      border-color: #00FDFF;
      transition: none !important;
    }

    .el-checkbox__inner::after {
      width: 4px;
      height: 10px;
      border: 3px solid var(--main-bg-color);
      border-left: 0;
      border-top: 0;
      left: 5px;
      top: 0px;
      transition: none !important;
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 7px;
    }

    // .el-checkbox__input.is-checked .el-checkbox__inner,
    // .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    // .el-checkbox__inner:hover {
    //   border-color: #00FDFF;
    // }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #00FDFF !important;
    }

    // .el-checkbox__inner::after {
    //   border-color: var(--main-bg-color);
    //   margin-left: 0;
    // }

    .el-tree-node:focus>.el-tree-node__content {
      background: #043846;
    }

    // .el-tree-node>.el-tree-node__children {
    //   overflow: visible !important;
    // }

    // .el-tree-node.is-expanded.is-focusable .el-tree-node__children {
    //   display: block;
    //   height: auto !important;
    // }

    // .el-tree-node .el-tree-node__children {
    //   height: 0 !important;
    // }

    // .el-tree-node.is-expanded.is-focusable {
    //   overflow: visible;
    // }

    // >.el-tree-node {
    //   overflow-y: clip;
    // }
  }

  .nav {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin: 0px auto;
    font-size: 16px;
    color: #fff;
    padding-top: 10px;

    &_box {
      width: 100%;
      margin: auto;
    }

    &_name {
      flex: 1;
      height: 100%;
      background: url('../../assets/group/nav.png');
      background-size: 100% 100%;
      margin-right: 4px;
      cursor: pointer;
    }

    &_name:last-child {
      margin-right: 0px;
    }

    &_name:hover {
      background: url('../../assets/group/nav1.png') !important;
      background-size: 100% 100% !important;
    }

    &_name_active {
      background: url('../../assets/group/nav1.png') !important;
      background-size: 100% 100% !important;
    }
  }

  .screen {
    width: 100%;
    margin: auto;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 0px;

    .screen_box {
      flex: 1;

      /deep/.el-input__inner {
        width: 190px;
        height: 38px;
        line-height: 38px;
        margin-left: 10px;
        // padding: 0px 20px;
        border: 1px solid #25C3C0;
        background-color: transparent;
        color: #ffffff !important;

        .el-input__inner {
          line-height: 28px;
        }
      }

      /deep/.el-select__input {
        width: 150px !important;
      }
    }

    img {
      width: 44px;
      height: 31px;
    }
  }

  .treeLevel {
    width: calc(100%);
    padding: 15px 0px;
    overflow: auto;

    &_tree {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &_left {
      flex: 1;
      text-align: left;

      &_label {
        width: 105px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_total {
        // width: 80px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_right {
      font-size: 12px;
      color: #8a8a8a;
      margin-right: 8px;
      position: relative;
    }

    .treeLevel_popup_one {
      position: fixed !important;
      left: 300px !important;
      // top: 755px !important;
      top: 735px !important;
    }
  }

  /deep/.el-input__icon {
    line-height: 28px !important;
  }

  /deep/.el-select .el-input__suffix {
    right: 10px;
  }

  /deep/.el-select .el-input .el-select__caret {
    color: #00FDFF !important;
    font-size: 24px;
  }

  .search {
    width: 100%;
    height: 38px;
    border: 1px solid #4AFFFF;

    &_input {
      flex: 1;
      height: 100%;
      background-color: transparent;
      color: #ffffff !important;

      /deep/.el-input__inner {
        width: calc(100% - 42px);
        // width: 100%;
        height: 30px;
        line-height: 30px;
        background-color: transparent;
        color: #ffffff !important;
        border: none;
        border-radius: 0px;
        padding: 0px;
      }

      img {
        width: 20px;
        height: 19px;
        margin: 0px 13px;
      }
    }

    &_but {
      width: 77px;
      height: 100%;
      // line-height: 30px;
      text-align: center;
      background-color: #00FDFF;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 34px;
        height: 33px;
      }
    }
  }
}

.treeLevel_popup {
  width: 135px;
  background: #03313e;
  background-size: 100% 100%;
  padding: 8px 0px;
  color: #ffffff;
  transition: 0.1s;
  font-size: 12px;
  margin-left: -2px;
  text-align: center;
  position: relative;
  margin-top: -2px;

  &_bg {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;

    &:hover {
      background-color: #043846;
    }
  }

  &_box {
    width: 90%;
    margin: auto;
    margin-bottom: 5px;

    div {
      // flex: 1;
      text-align: center;
    }

    /deep/.el-switch__core {
      width: 28px !important;
      height: 14px !important;
    }

    /deep/.el-switch__core:after {
      width: 10px !important;
      height: 10px !important;
    }

    .is-checked/deep/.el-switch__core:after {
      margin-left: -12px !important;
    }
  }

  &_ms {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &_num {
      width: 35px;
      height: 13px;
      line-height: 13px;
      text-align: center;
      border: 1px solid #666;
      color: #ffffff;
      margin-right: 5px;

      &_red {
        color: #4affff !important;
        border: 1px solid #4affff !important;
      }
    }

    &_num:last-child {
      margin: 0px;
    }
  }
}

.blue {
  color: #00FDFF !important;
}

.blue_qs {
  color: #058D8E;
}

.black {
  color: #343434;
}

.yelow {
  color: #FFB400;
}

::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
  display: none;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
  display: none;
}

/deep/ .el-input__inner::placeholder {
  color: #ffffff !important;
}
</style>
