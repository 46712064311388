/*
 * @Author: lbw
 * @Date: 2022-09-17 15:14:38
 * @LastEditors: lbw
 * @LastEditTime: 2022-09-18 13:42:40
 * @Description:
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import echarts from "echarts";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import request from "./api/request";
import qs from "qs";
import Cookie from "js-cookie";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import VideoPlayer from "vue-video-player";

import md5 from 'js-md5';
import { MessageError, MessageInfo, MessageSuccess, MessageWarning, customMessageError } from '@/api/message'
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.component("downloadExcel", JsonExcel);
Vue.use(ElementUI);
Vue.use(VideoPlayer);

Vue.prototype.$Cookie = Cookie; //cookie
Vue.prototype.axios = axios; //axios请求
Vue.prototype.$qs = qs; //qs
Vue.prototype.$request = request; //接口
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;
Vue.prototype.$MessageError = MessageError;
Vue.prototype.$MessageInfo = MessageInfo;
Vue.prototype.$MessageSuccess = MessageSuccess;
Vue.prototype.$MessageWarning = MessageWarning;
Vue.prototype.$customMessageError = customMessageError;

window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL)
})

Vue.directive("preventReClick", {  // button节流，在button中添加v-preventReClick即可控制按钮，防止按钮连击，时间限制2s
  inserted(el) {
    el.addEventListener("click", () => {
      if (el.style.pointerEvents !== "none") {
        el.style.pointerEvents = "none";
        setTimeout(() => {
          el.style.pointerEvents = "";
        }, 2000);
      }
    });
  }
});

// 路由守卫
router.beforeEach((to, from, next) => {
  var routeName = to.name;
  // document.title = to.meta.title;
  //登录页面不用鉴权
  if (routeName === "Login") {
    store.state.sosPushInfo = [];
    store.state.groupUserData = [];
    store.state.groupData = [];
    store.state.videoCallInfo.echoList = [];
    store.state.recordLable = [];
    store.state.recentlyCallRecord = [];
    store.state.isDataAnalysis = false;
    store.state.isVideoOne = true;
    sessionStorage.removeItem("groupArray");
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("openArray");
    sessionStorage.removeItem("radioBroadcastState");
    sessionStorage.removeItem("checkedArray");
    sessionStorage.removeItem("recentlyCallRecord");
    next();
  } else {
    var userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      next(); //有令牌可以进入
    } else {
      next("/Login"); //返回登录授权，并且记录需要返回的路由
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
