let reg = {
    // 6-16位密码，英文数字
    rPassword: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_])[a-zA-Z0-9\W_]{8,16}$/,

     // 中文，数字，字母，下划线，不能以下划线开头
    noCharacter:(value)=>{
        value = value.replace(/\s*/g,"");//去除所有空格
        value = value.replace(/[^\u4e00-\u9fa5\w]/g,"");//去除符号
        value = value.replace(/[_]/g,"");//去除下划线
        return value
    },

    // 1-15 个中文，数字，字母，下划线，不能以下划线开头
    wAgentName: /^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]{1,15}$/,

    // 4-20 个中文，数字，字母，下划线，不能以下划线开头
    rAgentName: /^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]{4,20}$/,

    // 2-15 个中文，数字，字母，
    rGroupName: /^[a-zA-Z0-9\u4e00-\u9fa5]{2,15}$/,

    // 6-20 登录账户 由 英文，数字和_组成，不能以下划线开头
    rAccount: /^(?!_)[a-zA-Z0-9_]{6,20}$/,

    // 2-10 个中文，数字，字母，下划线，不能以下划线开头
    rUserName: /^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]{2,7}$/,

    // 2-10 个中文，数字，字母，下划线，不能以下划线开头
    rEUserName: /^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]{2,10}$/,

    // 2-12位数字，中文，英文组成
    rDefaultName:/^[a-zA-Z0-9\u4e00-\u9fa5]{2,12}$/,

    // 手机号码
    rPhone: /^1[3456789]\d{9}$/,
    enrPhone: /^[0-9]*$/,
    //允许手机号为空的手机验证
    isEmptyPhone:/^\s{0}$|^((17[0-9])|(14[0-9])|(13[0-9])|(15[^4,\D])|(19[^4,\D])|(18[0,1,2,5-9]))\d{8}$/,

    // email
    rEmail: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})+$/,

    // 正整数
    rInt: /^\+?[1-9][0-9]*$/,

    // 验证是否为数字
    rNum: /^\d+$/,

    // 中文，数字，字母，
    rBroad: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,

    // iccid 号码 19-20位数字、字母
    rIccid: /^[\da-zA-Z]{19,20}$/,
}

export default reg