<template>
  <!-- 右侧 -->
  <div :class="['overall', $route.meta.navId != 1 ? 'overallbh' : '']">
    <!-- 右侧数据分析 -->
    <div :class="['right', $route.meta.navId != 1 ? 'rightbh' : '']">
      <div class="right_Statistics margin_bottom">
        <div class="right_box">
          <div class="right_title" style="margin-top: 10px;">
            <div class="right_title_text">今日统计</div>
          </div>
          <div class="right_nuber">
            <div class="right_bg" v-for="(item, index) in statistics.onlineUserTotal" :key="index">
              {{ item }}
            </div>
            <div style="font-size: 14px; color: #ffffff; letter-spacing: 1px">
              当下在线用户数
            </div>
          </div>
          <div class="right_bottom flex_align">
            <div class="right_bottom_box">
              <div class="right_bottom_title">
                <div class="circular circular_qs"></div>
                固定群组
              </div>
              <div class="right_bottom_num" style="color: #0cd8d6">
                {{ statistics.fixedGroup }}
              </div>
            </div>
            <div class="right_bottom_box">
              <div class="right_bottom_title">
                <div class="circular circular_fs"></div>
                用户总数
              </div>
              <div class="right_bottom_num" style="color: #ff7675">
                {{ statistics.userTotal }}
              </div>
            </div>
            <div class="right_bottom_box">
              <div class="right_bottom_title">
                <div class="circular circular_ls"></div>
                围栏记录
              </div>
              <div class="right_bottom_num" style="color: #14b0ff">
                {{ statistics.enclosureCount }}
              </div>
            </div>
            <div class="right_bottom_box">
              <div class="right_bottom_title">
                <div class="circular circular_red"></div>
                SOS记录
              </div>
              <div class="right_bottom_num" style="color: #ff0000">
                {{ statistics.sosCount }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_contrast" style="margin-bottom:10px;">
        <div class="right_box">
          <div class="right_title">
            <div class="right_title_text">7天在线用户数对比</div>
          </div>
          <div id="echartdays"></div>
        </div>
      </div>
      <div class="right_contrast" style="margin-bottom:10px;">
        <div class="right_box">
          <div class="right_title" style="margin-bottom:18px;">
            <div class="right_title_text">30天预警对比</div>
          </div>
          <!-- <div class="earlywarning flex_align">
            <div class="flex_align">
              <div class="earlywarning_wl"></div>
              围栏预警
            </div>
            <div class="flex_align">
              <div class="earlywarning_sos"></div>
              SOS预警
            </div>
          </div> -->
          <div id="echartmain" class="margin_auto" :style="{ height: echartsData.height, width: echartsData.width }">
          </div>
        </div>
      </div>
      <div class="right_contrast">
        <div class="right_box">
          <div class="right_title">
            <div class="right_title_text">最近接收记录</div>
          </div>
        </div>
        <div class="right_contrast_userIntercom">
          <div :class="['flex_align', index == 0 ? 'color_blue' : '']" v-show="index <= 3"
            v-for="(item, index) in $store.state.recentlyCallRecord" :key="index">
            <div :class="['circular', index == 0 ? 'circular_qs' : 'circular_qs_tow']" style="margin-right:10px;"></div>
            <div class="flex_grow">{{ item.time }}</div>
            <div class="flex_grow" style="text-align: right;">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
<script>
import {
  getNearly7DaysOnline,
  getAlarmLogCount,
  getAlarmByMonth
} from "../administration/dispatchLntercom.js";
import EventBus from "@/eventBus/eventBus.js";
import { _debounce } from "@/method/utils.js";
import echarts from "echarts";
export default {
  components: {},
  data() {
    return {
      sevenDaysData: [], //七天数据
      userInfo: {}, //用户数据
      echartsData: {
        //echarts数据
        width: "100%",
        height: "150px",
      },
      statistics: {
        //統計
        onlineUserTotal: [0, 0, 0, 0, 0], //在线用户总数
        userTotal: 0, //用户总数
        fixedGroup: 0, //固定群组数量
        enclosureCount: 0, //电子围栏记录数
        sosCount: 0, //sos记录数
      },
      visible: true
    };
  },
  props: {

  },
  created() {
    this.getAlarmLogCount();
  },
  mounted() {
    EventBus.$on("sosTips",  ()=> {
      //语音对讲
      this.getAlarmLogCount();
    });
    this.$nextTick(() => {
      if(document.getElementById("echartmain")){
        this.hzEcharts();
      }
      if(document.getElementById("echartdays")){
        this.getNearly7DaysOnline();
      }      
    });
  },
  // destroyed(){
  //   EventBus.$off("sosTips");
  // },
  beforeDestroy() {
    EventBus.$off("sosTips");
    // this.$store.state.isDataAnalysis = true;
  },
  filters: {},
  methods: {
    getAlarmLogCount: _debounce(function () {
      //告警数统计
      let that = this;
      getAlarmLogCount({
        uId: that.$store.state.userInfo.id,
      }).then((res) => {
        let data = res.data.data;
        that.statistics.enclosureCount = data.enclosureCount;
        that.statistics.sosCount = data.sosCount;
      });
    }, 1000),
    getNearly7DaysOnline() {
      //七天用户数量对比
      let that = this;
      let data = {
        uId: that.$store.state.userInfo.id,
      };
      let myCharts = this.$echarts.init(document.getElementById("echartdays"),null, {renderer: 'svg'});
      getNearly7DaysOnline(data).then((res) => {
        let arr = res.data.data.reverse();
        let xarr = arr.map((item) => that.$moment(item.date).format("MM-DD"));
        let yarr = arr.map((item) => item.count);
        myCharts.setOption({
          // tooltip: {
          //   trigger: "axis",
          //   axisPointer: {
          //     type: "cross",
          //     crossStyle: {
          //       color: "#7FD6FF",
          //     },
          //   },
          // },
          grid: {
            top: '16%',
            right: '0%',
            left: '0%',
            bottom: '16%'
          },
          xAxis: [
            {
              type: "category",
              data: xarr,
              splitLine:{
                  show:false,
              },
              axisTick: {
                  show: false // 不显示轴刻度
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#ffffff",
                },
              },
              axisLabel: {
                formatter: "{value}",
              }
            },
          ],
          yAxis: [
            {
              type: "value",
              // boundaryGap:[0,'10%'],
              axisLine: {
                show: false,
                lineStyle: {
                  color: "transparent",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#7FD6FF",
                  width: "1",
                  dashOffset: 10
                },
              },
              axisLabel: {
                formatter: "{value}",
              },
            }
          ],
          series: [
            {
              type: "bar",
              data: yarr,
              barWidth:'15px',
              label: {
                  show: true,
                  color:'#FDFF5E',
                  position: 'top' // 设置标签显示在顶部
              },
              itemStyle: {
                color: "#0DF8F7",
                normal: {
                  color: function () {
                    return new echarts.graphic.LinearGradient(0,0,0,1, // 表示渐变方向，从上到下
                      [
                        { offset: 0, color: "#0DF8F7" }, // 渐变起始颜色
                        { offset: 1, color: "#0383F5" }, // 渐变结束颜色
                      ],
                      false
                    );
                  }
                }
              },
            },
            {
              type: "line",
              data: yarr,
              symbolSize: 6,
              symbol:'circle',
              itemStyle: {
                normal: {
                  color: "#FDFF5E",
                  borderColor: '#FDFF5E',
                  borderWidth: 2
                },
              },
              emphasis: {
                  focus: 'series'
              },
              lineStyle: {
                  normal: {
                      width: 2,
                      color: "#FDFF5E"
                  }
              }
            },
          ],
        });
      });
    },
    hzEcharts() {
      //绘制echarts
      let myChart = this.$echarts.init(document.getElementById("echartmain"),null, {renderer: 'svg'});
      getAlarmByMonth({
        uId: this.$store.state.userInfo.id,
      }).then((res) => {
        // console.log(res)
        let data = res.data.data;
        let timeData = data.enclosureList.map((item) => item.date);
        let enclosureList = data.enclosureList.map((item) => item.count); //围栏预警
        let sosList = data.sosList.map((item) => item.count); //sos预警
        myChart.setOption({
          xAxis: {
            show: false,
            data: timeData,
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#4AFFFF",
                width: "1",
              },
            },
          },
          grid: {
            left: 0,
            right: 0,
            bottom: -10,  //0
            top: 40,  //25
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          yAxis: {
            axisLine: {
              show: false,
              lineStyle: {
                color: "#4AFFFF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#4AFFFF",
                width: "0.5",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            // orient: "vertical",
            data: ["围栏预警", "SOS预警"],
            x:'left',
            y:'top',
            itemHeight: 5,
            itemGap: 15,
            textStyle: {
              color: "#fff",
              fontSize: 16
            },
            // top: 0,
            left: -5
          },
          
          series: [
            {
              name: "围栏预警",
              smooth: true,
              symbol: "line",
              type: "line",
              symbolSize: 2,
              itemStyle: {
                normal: {
                  color: "yellow",
                  lineStyle: {
                    width: 2,
                  },
                },
              },
              data: enclosureList,
              animationDuration: 1000,
              animationEasing: "cubicInOut",
            },
            {
              name: "SOS预警",
              smooth: true,
              type: "line",
              symbol: "line",
              symbolSize: 2,
              itemStyle: {
                normal: {
                  color: "red",
                  lineStyle: {
                    width: 2,
                  },
                },
              },
              data: sosList,
              animationDuration: 1000,
              animationEasing: "quadraticOut",
            },
          ],
        });
      });
    }
  },
  watch: {
    "$store.state.groupOnline": {
      handler(newValue) {
        if (newValue.length > 0) {
          let that = this;
          let fixedGroup = newValue[0].GroupType.filter((item) => item.GroupType == 1);
          let userTotal = newValue[0].onlineArray.filter((item) => item.online == 3);
          that.statistics.fixedGroup = fixedGroup.length;
          that.statistics.userTotal = newValue[0].onlineArray.length;
          let num = JSON.stringify(userTotal.length).padStart(5, "0");
          let m = num.split("");
          that.statistics.onlineUserTotal = m;
        }
      },
      // deep: true,
      immediate: true,
    }
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
}

.overallbh {
  width: 398px !important;
  // height: 901px;
  position: absolute !important;
  top: 75px;
  right: 10px;
  background: url(../assets/statistics.png);
  background-size: 100% 100%;
  background-color: transparent !important;
  // background: #073741 !important;
  // border-radius: 10px !important;
  // border: 1px solid rgba(43, 223, 223, 0.36) !important;
  z-index: 10;
}

.rightbh {
  margin: 0px auto 0px auto !important;
}

.overall {
  width: 100%;
  margin: 0px auto;
  background-color: var(--main-group-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .horizontal {
    display: flex;
    align-items: center;

    &_img {
      width: 120px;
      height: 54px;
    }

    .mian-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
    }

    .overall-horizontal {
      width: 190px;
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: #fff;
      margin-left: 8px;
      border: 1px solid #3b7777;
      border-radius: 5px;
      box-shadow: 0 0 5px 0px #3b7777;
    }
  }

  .right {
    width: 364px;
    margin: 40px auto 0px auto;
    // margin: 71px auto 0px auto;

    &_box {
      margin: 0px auto;
    }

    &_Statistics {
      width: 100%;
      position: relative;
    }

    &_title {
      width: 100%;
      // height: 157px;
      // font-size: 20px;
      font-size: 14px;
      color: #fff;
      text-align: left;
      border-bottom: 1px solid #059098;

      &_text {
        min-width: 125px;
        display: inline-block;
        padding: 0px 10px 10px 0px;
        border-bottom: 2px solid #00fdff;
      }
    }

    &_nuber {
      width: 100%;
      display: flex;
      align-items: self-end;
      color: #fff;
      margin: 20px 0px;
    }

    &_bg {
      width: 39px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      background-color: #486c6b;
      background: url(../assets/usernum.png);
      background-size: 100% 100%;
      color: #fff;
      font-family: fangsong;
      margin-right: 10px;
    }

    &_bottom {
      width: 100%;
      color: #fff;

      &_box {
        width: 25%;
        text-align: center;
      }

      &_title {
        margin-bottom: 5px;
        font-size: 13px !important;
      }

      &_num {
        font-size: 24px !important;
        color: #fff;
        padding-left: 27px;
      }
    }

    &_name {
      width: 100%;
      color: #fff;
      font-size: 12px;
      margin-bottom: 10px;
    }

    &_dub {
      width: 100%;
      color: #fff;
      font-size: 12px;
      margin-bottom: 4px;

      /deep/.el-progress-bar__inner {
        background-image: linear-gradient(90deg, #448183 35%, #48d3d4 65%) !important;
      }
    }

    &_num_color {
      color: #4affff;
    }

    &_num_bg {
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      border-radius: 50%;
    }

    &_flex {
      flex: 1;
      text-align: left;
      padding: 0px 10px;
    }

    &_contrast {
      width: 100%;

      &_userIntercom {
        min-height: 145px;
        padding: 22px 0px;
        color: #666666;

        &>div {
          margin-top: 20px;
        }

        &>div:first-child {
          margin-top: 0px;
        }
      }
    }

    &_tian {
      width: 38px;
      font-size: 12px;
    }

    .bg_one {
      background-color: #49b5b5;
    }

    .bg_tow {
      background-color: #458f91;
    }

    .bg_three {
      background-color: #426164;
    }

    .bg_four {
      background-color: #42575a;
    }
  }
}

.earlywarning {
  width: 100%;
  color: #ffffff;
  margin-top: 20px;
  padding-left: 15px;

  &>div:last-child {
    margin-left: 15px;
  }

  &_wl {
    width: 26px;
    height: 4px;
    background-color: #d96851;
    margin-right: 5px;
  }

  &_sos {
    width: 26px;
    height: 4px;
    background-color: #00fdff;
    margin-right: 5px;
  }
}

#echartdays {
  width: 100%;
  height: 170px;
  margin: auto;
}

.circular {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
}

.circular_qs {
  background-color: #0cd8d6;
}

.circular_fs {
  background-color: #ff7675;
}

.circular_ls {
  background-color: #14b0ff;
}

.circular_red {
  background-color: #ff0101;
}

.circular_qs_tow {
  background-color: #028E94;
}

.color_blue {
  color: #ffffff;
}

.margin_bottom {
  margin-bottom: 30px;
}

.flex_align {
  display: flex;
  align-items: center;
}

.statistics {
  position: absolute;
  z-index: 1;
}
</style>
